import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import WelletContext from "../../context/WelletContext";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  formatDateNormal,
  generateDisabledDates,
  getLocale,
} from "../../helpers/dates";

import {
  setReservation,
  resetReservation,
  setReservationType,
} from "../../actions/reservationActions";

import ReservationTicketInfo from "../ReservationTicketInfo/ReservationTicketInfo";
import Header from "../../components/Header/Header";
import Money from "../../components/Money/Money";

import ButtonReservation from "../../components/Common/Button/ButtonReservation";
import WarningBox from "../../components/Common/WarningBox/WarningBox";
import ProductModal from "../../components/Common/ProductModal/ProductModal";
import Calendar from "../../components/Common/Calendar/Calendar";
import ErrorBox from "../../components/Common/ErrorBox";
import EmptyActivity from "../../components/Common/EmptyActivity/EmptyActivity";

import ChevronIcon from "../../components/Icons/ChevronIcon";
import CalendarReservationIcon from "../../components/Icons/CalendarReservationIcon";

import "./reservation.css";
import styles from "./reservation.module.css";
import Product from "../../components/Product/Product";
import Modal from "../../components/Modal/Modal";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  addExtraProduct,
  removeExtraProduct,
  setExtraProductList,
} from "../../actions/extraProductActions";

const ReservationVip = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [searchParams] = useSearchParams();

  const { showUrl, reservationType } = useParams();
  const refReferenceCode = searchParams.get("refReferenceCode");

  const date = useSelector((state) => state.reservation.date);
  const extraProducts = useSelector((state) => state.extraProducts.items);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [show, setShow] = useState(true);
  const [compareToday, setCompareToday] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const currency = useSelector((state) => state.app.currencies[0].shortForm);

  const [showMap, setShowMap] = useState(false);
  const imgRef = useRef();
  const [hasCallCenter, setHasCallCenter] = useState(null);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  const getShow = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/get/${showUrl}`,
        {
          params: {
            lang: language,
            reservationType,
          },
        }
      );

      setShow(result.data);

      const todayDate = new Date(result.data.calendar.minDate);

      const yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const firstDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        1
      );

      setCompareToday(todayDate);
      setMinDate(todayDate);

      setDisabledDates(generateDisabledDates(firstDate, yesterdayDate, locale));
      setSelectedDate(getDate(todayDate));
      getProducts(result.data.id, format(todayDate, "yyyy-MM-dd"));

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getDate = (todayDate) => {
    let result;

    if (date !== null) {
      result = new Date(date + "T00:00:00");

      if (result < todayDate) result = todayDate;

      return result;
    }

    return todayDate;
  };

  const getProducts = async (showId, date) => {
    setLoadingProducts(true);
    setError(null);
    try {
      const result = await welletContext.apis.tickets.get("/products/get", {
        params: {
          showId,
          date,
          lang: language,
          type: reservationType,
        },
      });

      const initializedProducts = result.data.products.map((product) => ({
        ...product,
        quantity: 0,
        disabled: false,
      }));
      setProducts(initializedProducts);
      if (initializedProducts.length === 0) {
        setError(t("productsNotAvailables"));
      } else {
        setLoadingProducts(false);
      }
      dispatch(setExtraProductList(result.data.extraProducts));
    } catch (error) {
      if (error.response.data) {
        setError(t("reservationNotAvailables"));
      } else {
        setError(t("callApiError"));
      }
      console.error(error);
    }
  };
  useEffect(() => {
    if (show && show.reservationTypes && Array.isArray(show.reservationTypes)) {
      const reservation = show.reservationTypes.find(
        (item) =>
          item.type.trim().toLowerCase() ===
          reservationType.trim().toLowerCase()
      );

      if (reservation) {
        const hasCallCenter = reservation.hasCallCenter;
        setHasCallCenter(hasCallCenter);

        dispatch(setReservationType(reservationType));
      }
    }
  }, [show]);
  useEffect(() => {
    getShow();
    dispatch(setReservationType(reservationType));
  }, []);

  const handleChangeDate = async (day) => {
    if (day !== undefined) {
      setProducts([]);
      setSelectedDate(day);
      getProducts(show.id, format(day, "yyyy-MM-dd"));
    }
    setCalendarVisible(!calendarVisible);
  };

  const handleAddProduct = (productId) => {
    const index = products.findIndex((p) => p.id === productId);
    handleIncrement(index);
  };

  const handleIncrement = (index) => {
    const product = products[index];

    if (product.stock && product.stock === product.quantity) return;

    let newProducts = [...products];

    if (product.isExclusive) {
      newProducts
        .filter((p) => p.isExclusive && p.id !== product.id)
        .forEach((p) => (p.disabled = true));
    }

    newProducts[index].disabled = false;
    newProducts[index].quantity = newProducts[index].stock
      ? Math.min(newProducts[index].stock, newProducts[index].quantity + 1)
      : newProducts[index].quantity + 1;

    setProducts(newProducts);

    if (product.extraPax > 0) {
      dispatch(
        addExtraProduct({ ...product, quantity: 0, specialType: "EXTRAPAX" })
      );
    }
  };

  const handleDecrement = (index) => {
    let newProducts = [...products];

    // Decrementa la cantidad de productos
    newProducts[index].quantity = Math.max(0, newProducts[index].quantity - 1);

    // Si el producto tiene extraPax, ajusta la cantidad de extraPax en consecuencia
    if (newProducts[index].extraPax > 0 && newProducts[index].quantity === 0) {
      newProducts[index].extraPax = 0; // Restablece extraPax si la cantidad es 0
    }

    if (newProducts[index].isExclusive && newProducts[index].quantity === 0) {
      newProducts.forEach((p) => (p.disabled = false));
    }
    dispatch(
      removeExtraProduct({
        ...newProducts[index],
        quantity: 0,
        specialType: "EXTRAPAX",
      })
    );
    setProducts(newProducts);
  };

  const sumTotal = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.prices.price * p.quantity, 0);
  };

  const totalConsume = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.prices.consumption * p.quantity, 0);
  };

  const totalPax = () => {
    return products
      .filter((p) => p.quantity > 0)
      .reduce((total, p) => total + p.pax * p.quantity, 0);
  };

  const hasItems = products?.some((p) => p.quantity > 0);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const finalProductsQuantity = products
    .filter((product) => product.quantity > 0)
    .map((product) => ({
      id: product.id,
      quantity: product.quantity,
      extraPaxs: 0,
      specialType: product.specialType,
    }));

  const finalProducts = finalProductsQuantity.map((product) => {
    return {
      ...product,
      extraPaxs: 0,
    };
  });

  const onInfoClick = (id) => {
    setSelectedProduct(id);
    toggleModal();
  };

  const handleContinue = () => {
    const reservationInfo = {
      showId: show.id,
      showName: show.name,
      products: finalProducts,
      date: format(selectedDate, "yyyy-MM-dd"),
      callCenter: hasCallCenter,
    };

    dispatch(setReservation(reservationInfo));

    if (extraProducts.length > 0) {
      navigate(`/venue/${showUrl}/vip-reservation-extra`);
    } else {
      navigate(`/venue/${showUrl}/reservation-check`);
    }
  };

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="reservation button-fixed">
      <Header
        title={
          <div className="text-capitalize px-3">
            {t("book") + " " + show.name}
          </div>
        }
      />

      {compareToday !== null && selectedDate ? (
        <>
          <div className="mt-2">
            {refReferenceCode && (
              <div className="mb-3">
                <WarningBox
                  title={t("modifyAlertTitle")}
                  subtitle={t("modifyAlertText", {
                    code: refReferenceCode,
                    show: show.name,
                  })}
                />
              </div>
            )}
            <span className="text-secondary fw-bold">
              {t("reservationDate")}
            </span>
            <div className="font-medium fw-bold" onClick={setCalendarVisible}>
              {formatDateNormal(selectedDate, locale)}
              <CalendarReservationIcon
                size={"1.4rem"}
                color={"var(--color-primary)"}
                styles={{ marginLeft: ".5rem", marginBottom: "-5px" }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col fw-bold">
              {reservationType === "area"
                ? t("reservationarea")
                : t("selectMenu")}
            </div>
            <div
              className={`font-tiny col-auto ${
                show?.seatDistributionImgUrl && reservationType === "area"
                  ? ""
                  : "d-none"
              }`}
              onClick={() => setShowMap(!showMap)}
            >
              <div className="d-flex align-items-center">
                {showMap ? (
                  <>
                    {t("hideMap")}
                    <span className="view-more">-</span>
                  </>
                ) : (
                  <>
                    {t("viewMap")}
                    <span className="view-more">+</span>
                  </>
                )}
              </div>
            </div>
          </div>
          {error ? (
            <div className="mt-3">
              <EmptyActivity subtitle={error} />
            </div>
          ) : (
            !loading && (
              <>
                <div
                  className={`mt-2 fade-up ${showMap ? "d-block" : "d-none"}`}
                >
                  <div className="image-map">
                    <QuickPinchZoom onUpdate={onUpdate} centerContained={true}>
                      <img
                        ref={imgRef}
                        src={show.seatDistributionImgUrl}
                        alt="map"
                      />
                    </QuickPinchZoom>
                  </div>
                </div>
                <div className="mt-3">
                  {products.map((p, i) => (
                    <Product
                      product={p}
                      handleIncrement={() => handleIncrement(i)}
                      handleDecrement={() => handleDecrement(i)}
                      onInfoClick={() => onInfoClick(p.id)}
                      isExtraProduct={false}
                    />
                  ))}
                </div>
              </>
            )
          )}

          {errorMessage && <ErrorBox title={errorMessage} />}
          {hasItems && (
            <div
              className="position-fixed w-100"
              style={{
                bottom: 0,
                left: 0,
                zIndex: 2,
              }}
            >
              <div className="container p-0">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-7 col-lg-6">
                    <ButtonReservation
                      leftContent={
                        <>
                          <div className="font-normal">
                            {t("total")} <Money value={sumTotal()} />
                          </div>
                          <div className="font-smaller">
                            {t("upToGuest", { count: totalPax() })}
                          </div>
                          {totalConsume() > 0 && (
                            <div className="font-smaller">
                              {t("consumptionCredit")}{" "}
                              <Money value={totalConsume()} />
                            </div>
                          )}
                        </>
                      }
                      rightContent={
                        <div>
                          {t("continue")}
                          <span className="ms-3">
                            <ChevronIcon
                              size={20}
                              fillColor={"#000"}
                              styles={{ marginBottom: "-4px" }}
                            />
                          </span>
                        </div>
                      }
                      size="large"
                      className={`${styles.btn}`}
                      onClick={handleContinue}
                      fixed={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
      <div className="mt-4 fw-semibold font-tiny">
        {t("priceIn", { currency: currency })}
      </div>

      <ProductModal
        open={calendarVisible}
        fullScreen
        animationClass="fade-up-horizontal"
        hasBackground
        showCloseButton={false}
      >
        <Header onClick={() => setCalendarVisible(false)} showBack />
        <Calendar
          minDate={minDate}
          disabledDates={disabledDates}
          onSelectDate={handleChangeDate}
          selectedDate={selectedDate}
        />
      </ProductModal>

      <Modal
        open={isModalOpen}
        onClose={() => onInfoClick()}
        animationClass="slide-left-right"
        fullScreen={true}
        hasBackground={false}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={selectedProduct}
          isExtraProduct={false}
        />
      </Modal>
    </div>
  );
};

export default ReservationVip;
