import { CircleSpinner } from "react-spinners-kit";

const LoadingScreen = () => {
  return (
    <div className="loading-app">
      <div className="loaderDiv">
        <CircleSpinner
          className="spinner-app mx-auto"
          style={{ margin: "auto" }}
          size={40}
          color="var(--color-font-primary)"
        />
      </div>
    </div>
  );
};
export default LoadingScreen;
