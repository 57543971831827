import React from 'react';
// import Button from '../Button/Button';
// import { useHistory } from 'react-router-dom';

import styles from './emptyActivity.module.css';

const EmptyActivity = ({ title, subtitle, paths, btnText }) => {
  // const history = useHistory();

  return (
    <div className={`card ${styles.container} fade-up`}>
      <div className='container container-empty-activity'>
        <div className='text-center'>
          <span className='bold'>{title}</span>
          <div className='empty-activity-span mt-1' style={{ color: 'var(--color-font-gray)' }}>
            {subtitle}
          </div>
          {/* {paths && btnText && (
            <div className='mt-3'>
              <Button
                onClick={() => history.push(paths)}
                text={btnText}
                size='medium'
                width='100%'
                hasBorder={true}
                icon={true}
                typeIcon='arrow'
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default EmptyActivity;
