export const getToken = (mercadopagoSDK, cardHolder) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await mercadopagoSDK.fields.createCardToken({
          cardholderName: cardHolder,
        });
  
        resolve(token.id);
      }
      catch (e) {
        reject(e);
      }
    });
  }
  