import { combineReducers } from "redux";
import appreducer from "./appReducer";
import currencyReducer from "./currencyReducer";
import reservationReducer from "./reservationReducer";
import extraProductsReducer from "./extraProductsReducer";
import associatesReducer from "./associatesReducer";

export default combineReducers({
  app: appreducer,
  currency: currencyReducer,
  reservation: reservationReducer,
  extraProducts: extraProductsReducer,
  associates: associatesReducer,
});
