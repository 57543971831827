import "./checkbox.css";

const Checkbox = ({ label, onChange }) => {
  return (
    <label className="container-checkbox">
      <div>{label}</div>
      <input type="checkbox" onChange={onChange} />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
