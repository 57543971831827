const InfoIcon = ({ color = 'var(--color-primary)', size = '17px', styles }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={styles}
    >
      <path
        d='M9.5 13V9.5M9.5 6H9.50875M5.825 17.375H13.175C14.6451 17.375 15.3802 17.375 15.9417 17.0889C16.4356 16.8372 16.8372 16.4356 17.0889 15.9417C17.375 15.3802 17.375 14.6451 17.375 13.175V5.825C17.375 4.35486 17.375 3.61979 17.0889 3.05827C16.8372 2.56435 16.4356 2.16278 15.9417 1.91111C15.3802 1.625 14.6451 1.625 13.175 1.625H5.825C4.35486 1.625 3.61979 1.625 3.05827 1.91111C2.56435 2.16278 2.16278 2.56435 1.91111 3.05827C1.625 3.61979 1.625 4.35486 1.625 5.825V13.175C1.625 14.6451 1.625 15.3802 1.91111 15.9417C2.16278 16.4356 2.56435 16.8372 3.05827 17.0889C3.61979 17.375 4.35486 17.375 5.825 17.375Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default InfoIcon;
