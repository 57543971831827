import React from 'react';
import styles from './reservationSelector.module.css';
import Slider from '../Slider/Slider';

const PaxSelector = ({
  handleDecrement,
  handleIncrement,
  selectedPax,
  handleCustomPax,
  showInput,
  sliderRef,
  pax,
  handleChangePerson,
  initialSlide,
}) => {
  return (
    <>
      {showInput ? (
        <>
          <div className={`fade-up d-flex justify-content-start align-items-center mt-2 py-1`}>
            <div className={styles.paxHandle} onClick={handleDecrement}>
              -
            </div>
            <input
              type='number'
              value={selectedPax}
              onChange={handleCustomPax}
              className={`${styles.customPax} fw-bold text-center`}
              readOnly
            />
            <div className={styles.paxHandle} onClick={handleIncrement}>
              +
            </div>
          </div>
        </>
      ) : (
        <Slider
          ref={sliderRef}
          slideSpacing={10}
          initialSlide={initialSlide}
          touchScroll={true}
          showBtns={false}
          visibleSlides={'all'}
        >
          {pax.map((pax, index) => (
            <div
              className={`${styles.paxContainer} ${
                selectedPax === pax ? styles.paxSelected : ''
              } text-center mt-2`}
              key={index}
              onClick={() => handleChangePerson(pax)}
            >
              {pax}
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};

export default PaxSelector;
