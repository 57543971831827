import React from "react";
import styles from "./SummaryBox.module.css";
import Money from "../Money/Money";

const SummaryBox = ({ title, subtitle, value }) => {
  return (
    <div className={`${styles.summaryBox}`}>
      <div>
        <div className="text-capitalize">{title}</div>
        <div className="fw-bold mt-1">
          <Money value={value} symbolSize="14px" lowDecimals decimal={false} />
        </div>
        <div className={`text-secondary ${styles.subtitle}`}>{subtitle}</div>
      </div>
    </div>
  );
};

export default SummaryBox;
