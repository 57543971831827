import React from "react";
import "./Modal.css";
import Header from "../Header/Header";

const Modal = ({
  open = false,
  onClose,
  animationClass,
  fullScreen = false,
  children,
  showCloseButton = true,
  idModal,
  hasBackground = false,
  backIcon = false,
  centered = false,
  className,
}) => {
  let fullscreenClass = fullScreen ? "modal-fullscreen" : "";
  let modalClass = open ? "modal show d-block" : "modal";

  if (className) modalClass += " " + className;

  const isModalFull = fullScreen
    ? {}
    : { position: "absolute", top: 0, right: 0 };

  const handleModalClose = () => {
    onClose();
  };

  const modalContentStyle = fullScreen
    ? {}
    : { maxHeight: "80vh", overflowY: "auto" };

  return (
    <div
      className={`${modalClass} ${animationClass} ${fullscreenClass}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      id={idModal}
    >
      <div
        className={`modal-dialog ${centered ? "modal-dialog-centered" : ""}`}
        role="document"
      >
        <div
          className={`modal-content ${hasBackground && "background-modal"}
          ${fullScreen && "container"}
          `}
          style={modalContentStyle}
        >
          <div className="row justify-content-center">
            <div className="col-12 col-md-7 col-lg-6">
              {showCloseButton && backIcon && (
                <Header onClick={handleModalClose} />
              )}

              {showCloseButton && !backIcon && (
                <div style={isModalFull}>
                  <button
                    type="button"
                    onClick={handleModalClose}
                    className="close font-large text-secondary"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
