import React, { useContext, useEffect, useState } from "react";
import WelletContext from "../../context/WelletContext";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setExtraPax } from "../../actions/reservationActions";

import Header from "../../components/Header/Header";
import Money from "../../components/Money/Money";

import ButtonReservation from "../../components/Common/Button/ButtonReservation";

import ChevronIcon from "../../components/Icons/ChevronIcon";

import "./reservation.css";
import styles from "./reservation.module.css";
import Product from "../../components/Product/Product";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Modal from "../../components/Modal/Modal";
import ReservationTicketInfo from "../ReservationTicketInfo/ReservationTicketInfo";

const ReservationVipExtra = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const { showUrl } = useParams();
  const language = useSelector((state) => state.app.language);
  const mainProducts = useSelector((state) => state.reservation.products);
  const extraProducts = useSelector((state) => state.extraProducts.items);
  const [products, setProducts] = useState(extraProducts);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState();
  const hasItems = products?.some((p) => p.quantity > 0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const addonsExtraPax = extraProducts.filter(
    (e) => e.specialType === "EXTRAPAX"
  );
  const addonsKids = extraProducts.filter((e) => e.specialType === "KIDS");
  const addonsOthers = extraProducts.filter(
    (e) => e.specialType !== "KIDS" && e.specialType !== "EXTRAPAX"
  );

  const getShow = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/get/${showUrl}`,
        {
          params: {
            lang: language,
          },
        }
      );

      setShow(result.data);

      setLoading(false);
    } catch (error) {}
  };

  const onInfoClick = (product) => {
    setSelectedProduct(product);
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderExtraPax = () => {
    return (
      <>
        <div className="mt-3 fw-bold">{t("addExtrasPax")}</div>
        <div className="mt-3 mb-5">
          {extraProducts.map((e, index) => {
            if (e.specialType === "EXTRAPAX")
              return (
                <Product
                  key={index}
                  product={e}
                  isExtraPax={true}
                  handleIncrement={() => handleIncrement(index)}
                  handleDecrement={() => handleDecrement(index)}
                />
              );
            else return null;
          })}
        </div>
      </>
    );
  };

  const renderKids = () => {
    return (
      <>
        <div className="mt-3 fw-bold">{t("addAdditional")}</div>
        <div className="mt-3 mb-5">
          {extraProducts.map((e, index) => {
            if (e.specialType === "KIDS")
              return (
                <Product
                  key={index}
                  product={e}
                  isExtraPax={false}
                  hasMoreInfo={true}
                  onInfoClick={() => onInfoClick(e.id)}
                  handleIncrement={() => handleIncrement(index)}
                  handleDecrement={() => handleDecrement(index)}
                />
              );
            else return null;
          })}
        </div>
      </>
    );
  };

  const renderOthers = () => {
    return (
      <>
        <div className="mt-3 fw-bold">{t("addSpecialAdditional")}</div>
        <div className="mt-3">
          {extraProducts.map((e, index) => {
            if (e.specialType !== "KIDS" && e.specialType !== "EXTRAPAX")
              return (
                <Product
                  key={index}
                  product={e}
                  isExtraPax={false}
                  onlyOne={true}
                  hasMoreInfo={true}
                  onInfoClick={() => onInfoClick(e.id)}
                  handleIncrement={() => handleIncrement(index)}
                  handleDecrement={() => handleDecrement(index)}
                />
              );
            else return null;
          })}
        </div>
      </>
    );
  };

  const getTotal = () => {
    let total = 0;
    extraProducts
      .filter((p) => p.quantity > 0)
      .forEach((product) => {
        if (product.specialType === "EXTRAPAX") {
          total += product.quantity * product.prices.priceExtraPax;
        } else if (product.specialType === "UPGRADE") {
          total += product.quantity * product.prices.price;
        } else total += product.quantity * product.prices.price;
      });

    return total;
  };

  const handleIncrement = (index) => {
    let newProducts = [...products];

    if (newProducts[index].isExclusive) {
      newProducts
        .filter((p) => p.isExclusive && p.id !== newProducts[index].id)
        .forEach((p) => (p.disabled = true));
    }

    if (newProducts[index].specialType === "EXTRAPAX")
      newProducts[index].quantity = Math.min(
        newProducts[index].extraPax,
        newProducts[index].quantity ? newProducts[index].quantity + 1 : 1
      );
    else if (newProducts[index].specialType === "UPGRADE") {
      newProducts[index].quantity = mainProducts
        .filter((p) => p.specialType === null)
        .reduce((t, p) => t + p.quantity, 0);
    } else {
      newProducts[index].quantity = Math.min(
        newProducts[index].stock ? newProducts[index].stock : 999,
        newProducts[index].quantity ? newProducts[index].quantity + 1 : 1
      );
    }

    setProducts(newProducts);
  };

  const handleDecrement = (index) => {
    let newProducts = [...products];

    if (newProducts[index].specialType === "UPGRADE")
      newProducts[index].quantity = 0;
    else
      newProducts[index].quantity = Math.max(
        0,
        newProducts[index].quantity - 1
      );

    if (newProducts[index].isExclusive && newProducts[index].quantity === 0) {
      newProducts.forEach((p) => (p.disabled = false));
    }

    setProducts(newProducts);
  };

  const handleContinue = () => {
    dispatch(setExtraPax(products.filter((p) => p.quantity !== undefined)));
    navigate(`/venue/${showUrl}/reservation-check`);
  };

  useEffect(() => {
    getShow();
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <>
      <Header
        title={
          <div className="text-capitalize px-3">
            {t("book") + " " + show.name}
          </div>
        }
      />
      <div className="mt-3">
        {addonsExtraPax.length > 0 && renderExtraPax()}
        {addonsKids.length > 0 && renderKids()}
        {addonsOthers.length > 0 && renderOthers()}
      </div>
      <div
        className="position-fixed w-100"
        style={{
          bottom: 0,
          left: 0,
          zIndex: 2,
        }}
      >
        <div className="container p-0">
          <div className="row justify-content-center">
            <div className="col-12 col-md-7 col-lg-6">
              <ButtonReservation
                leftContent={
                  hasItems ? (
                    <>
                      {t("total")} <Money value={getTotal()} />
                    </>
                  ) : (
                    t("noThanks")
                  )
                }
                rightContent={
                  hasItems ? (
                    <div className="d-flex justify-content-end">
                      {t("continue")}{" "}
                      <ChevronIcon
                        size={18}
                        fillColor={"#000"}
                        styles={{ marginTop: "2px", marginLeft: ".5rem" }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end">
                      <ChevronIcon
                        size={18}
                        fillColor={"#000"}
                        styles={{ marginTop: "2px" }}
                      />
                    </div>
                  )
                }
                backgroundColor={!hasItems ? "var(--color-deep-gray)" : null}
                size="large"
                className={`${styles.btn}`}
                onClick={handleContinue}
                fixed={true}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => onInfoClick()}
        animationClass="slide-left-right"
        fullScreen={true}
        hasBackground={false}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={selectedProduct}
          isExtraProduct={false}
        />
      </Modal>
    </>
  );
};

export default ReservationVipExtra;
