import React from 'react';
import { useTranslation } from 'react-i18next';
import Money from '../../Money/Money';

const Summary = ({ reservation }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='bold'>{t('summary')}</div>
      <div className='mt-2 light'>
        <div className='text-lighter-gray font-small'>
          <div className='row mt-1'>
            <div className='col pr-0'>{t('productsInfo.numberOfPeople')}:</div>
            <div className='col-auto'>{t('upToGuest', { count: reservation.paxs })}</div>
          </div>
          <div className='row mt-1'>
            <div className='col pr-0'>{t('productsInfo.priceDetailsFor')}:</div>
            <div className='col-auto'>
              {reservation.items} {reservation.items > 1 ? 'items' : 'item'}
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col pr-0'>Sub total:</div>
            <div className='col-auto'>
              <Money value={reservation?.subTotalAmount} symbolSize='14px' />
            </div>
          </div>
          {reservation.tipAmount && reservation.tipAmount > 0 ? (
            <div className='row mt-1'>
              <div className='col pr-0'>{t('productsInfo.tip')}:</div>
              <div className='col-auto'>
                <Money value={reservation?.tipAmount} symbolSize='14px' />
              </div>
            </div>
          ) : null}
          {reservation.taxes && reservation.taxes > 0 ? (
            <div className='row mt-1'>
              <div className='col pr-0'>{t('productsInfo.taxes')}:</div>
              <div className='col-auto'>
                <Money value={reservation?.taxes} symbolSize='14px' />
              </div>
            </div>
          ) : null}
        </div>

        <div className='row fw-bold mt-2'>
          <div className='col font-small pr-0'>Total:</div>
          <div  className='col-auto'>
            <Money value={reservation?.totalAmount} symbolSize='14px' />
          </div>
        </div>

        {reservation.remainingAmount > 0 ? (
          <>
            <div className='row mt-1'>
              <div className='col fw-bold pr-0'>{t('productsInfo.depositGuarantee')}:</div>
              <div className='col-auto fw-bold'>
                <Money value={reservation?.depositAmount} symbolSize='14px' />
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col fw-bold pr-0'>{t('productsInfo.balanceToBePaid')}:</div>
              <div className='col-auto fw-bold'>
                <Money value={reservation?.remainingAmount} symbolSize='14px' />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Summary;
