import React from "react";
import axios from "axios"

const WelletContext = React.createContext();

function WelletContextProvider({ children }) {

    const tickets = axios.create({
        baseURL: process.env.REACT_APP_SALES_API_URL
        });

    const apis = {
        tickets
    }

  return (
    <WelletContext.Provider value={{ apis }}>
      {children}
    </WelletContext.Provider>
  )
}

export { WelletContextProvider };
export default WelletContext;