import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import ImageModal from "../../components/ImageModal/ImageModal";
import styles from "./ReservationTicketInfo.module.css";
import Money from "../../components/Money/Money";
import Slider from "../../components/Slider/Slider";
import { getProduct } from "../../services/welletService";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";

const ReservationTicketInfo = ({ productId, isExtraProduct = false }) => {
  const [open, setOpen] = useState(false);
  const sliderRef = useRef();
  const [url, setUrl] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const lang = useSelector((state) => state.app.language);
  const handleClickImage = (image) => {
    setOpen(true);
    setUrl(image);
  };
  const getProductInfo = async () => {
    setLoading(true);
    try {
      const result = await getProduct(productId, lang);
      setProduct(result);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const renderSubtitle = (p) => {
    switch (p.specialType) {
      case "UPGRADE":
        return t("pricePerPerson");
      case "EXTRASERVICE":
        return t("pricePerUnit");
      default:
        if (p.reservationType === "AREA")
          return (
            <>
              {t("upToGuest", { count: p.pax })}
              {p.extraPax && p.extraPax > 0 ? (
                <span>
                  {" "}
                  + {p.extraPax} {t("extraGuests")} ($)
                </span>
              ) : null}
            </>
          );
        else return t("pricePerPerson");
    }
  };

  useEffect(() => {
    if (productId) getProductInfo();
  }, [productId]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {open && (
            <ImageModal onClose={() => setOpen(false)} hasBackground={true}>
              <img src={url} alt={product.name} />
            </ImageModal>
          )}

          <div className={`${styles.container}`}>
            <div className="font-large fw-bold">{product?.name}</div>
            <div className="light mt-1">{renderSubtitle(product)}</div>
            <div className="font-medium mt-1">
              <Money
                value={
                  isExtraProduct
                    ? product?.prices?.priceExtraPax
                    : product?.prices?.price
                }
                symbolSize="20px"
                decimal={false}
              />
            </div>
            <div className="mt-3">
              {product?.images.length > 0 && (
                <Slider
                  ref={sliderRef}
                  slideSpacing={10}
                  initialSlide={0}
                  touchScroll={true}
                  showBtns={false}
                  visibleSlides={"all"}
                >
                  {product?.images.map((i, index) => (
                    <div
                      key={index}
                      onClick={() => handleClickImage(i)}
                      className={`${styles.imageContainer} position-relative`}
                      style={{
                        backgroundImage: `url(${i})`,
                      }}
                    >
                      <div className={styles.seeMore}>
                        <div className="pb-1">+</div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {product?.description && (
                <>
                  <div className="mt-4 text-secondary font-normal fw-bold">
                    {t("description")}
                  </div>
                  <div className="mt-1 font-1rem fw-bold">{t("includes")}</div>
                  <div
                    className="mt-3 font-tiny"
                    dangerouslySetInnerHTML={{ __html: product?.description }}
                  />
                </>
              )}

              {product?.policy && (
                <>
                  <div className="mt-3 font-1rem fw-bold">{t("policies")}</div>
                  <div className="mt-2">
                    <div
                      className="mt-2 font-tiny"
                      dangerouslySetInnerHTML={{ __html: product?.policy }}
                    />
                  </div>
                </>
              )}
              {product?.changesCancellations && (
                <>
                  <div className="mt-3 font-1rem fw-bold">
                    {t("changesAndCancellations")}
                  </div>
                  <div className="mt-2 mb-4">
                    <div
                      className="mt-2 font-tiny"
                      dangerouslySetInnerHTML={{
                        __html: product?.changesCancellations,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReservationTicketInfo;
