import React, { useEffect, useState } from 'react';
import Modal from './Modal/Modal';
const MercadoPagoThreeDsModal = ({
  paymentId,
  externalResourceURL,
  creq,
  onChallengeCompleted,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!window.mp) {
      console.error('Mercado Pago SDK not loaded');
      return;
    }

    if(window.mpBricksBuilder !== undefined) {
        return;
    }

    window.mpBricksBuilder = window.mp.bricks();

    // settings created with https://www.mercadopago.com.ar/developers/es/live-demo/status-screen-brick
    const settings = {
    initialization: {
        paymentId,
        additionalInfo: {
        externalResourceURL,
        creq,
        },
    },
    customization: {
        visual: {
        hideStatusDetails: false,
        hideTransactionDate: false,
        style: {
            theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
            texts: {
            ctaReturnLabel: 'Continuar',
            },
        },
        },
        // backUrls: {
        //     'error': 'https://booking.wellet.dev/error', --> las urls tienen que estar en el mismo dominio
        //     'return': 'https://booking.wellet.dev/success'
        // }
    },
    callbacks: {
        onReady: () => {
        setIsReady(true);
        },
        onError: (error) => {
        console.log('Brick error', error);
        },
    },
    };

    const onChallengeCompletedHandler = (e) => {
        if (e.data.status === 'COMPLETE') {
            onChallengeCompleted && onChallengeCompleted();
        }
    }

    window.addEventListener('message', onChallengeCompletedHandler);

    window.statusScreenBrickController = window.mpBricksBuilder.create(
        'statusScreen',
        'statusScreenBrick_container',
        settings,
    );
    
    const onUnmount = () => {
      if (window.statusScreenBrickController?.unmount) window.statusScreenBrickController.unmount();
      delete window.mpBricksBuilder;
      window.removeEventListener('message', onChallengeCompletedHandler);
    };
    return onUnmount;
  }, [paymentId, externalResourceURL, creq]);

  //if(!isReady) return null;

  return (
    <Modal open={true} centered={true} showCloseButton={false} hasBackground={false} fullScreen={false}>
        <div id='statusScreenBrick_container'></div>
    </Modal>
  );
};

export default MercadoPagoThreeDsModal;