import React from "react";
import ChevronIcon from "../../Icons/ChevronIcon";
import EditIcon from "../../Icons/EditIcon";
import styles from "./button.module.css";
import QrIcon from "../../Icons/QrIcon";

const Button = ({
  text,
  size,
  icon = false,
  iconColor = "#fff",
  typeIcon = null,
  type = "submit",
  onClick,
  className,
  disabled = false,
  dataDismiss,
  ariaLabel,
  width = null,
  isClicked = false,
  isSelected = false,
  hasBorder = false,
  color = "#000",
  backgroundColor = "var(--color-primary)",
}) => {
  const sizeConfig = {
    large: {
      widthConfig: "100%",
      height: "4rem",
    },
    normal: {
      widthConfig: "100%",
      height: "3.2rem",
      fontSizeConfig: "1rem",
    },
    medium: {
      widthConfig: `${width}`,
      height: "45px",
      fontSizeConfig: "1rem",
    },
    small: {
      widthConfig: `${width}`,
      height: "35px",
      fontSizeConfig: "0.9rem",
    },
    card: {
      widthConfig: `${width}`,
      height: "70px",
      fontSizeConfig: "0.6rem",
    },
  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    fontSize: fontSizeConfig,
    color: color,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonWellet} fw-bold ${
        className ? className : null
      }  ${
        isSelected
          ? `${styles.btnSelected}`
          : isClicked
          ? styles.btnClickeable
          : ""
      } ${disabled && styles.disabledOpacity} ${
        hasBorder ? styles.border : ""
      }  position-relative ${
        typeIcon === "add" ? "justify-content-between" : ""
      }`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-dismiss={dataDismiss}
      aria-label={ariaLabel}
    >
      {icon && typeIcon === "edit" && (
        <span className="ms-1">
          <EditIcon size="14px" color={iconColor} />
        </span>
      )}
      <div className={typeIcon === "add" ? styles.textAdd : ""}>{text}</div>
      {icon && typeIcon === "arrow" && (
        <ChevronIcon
          size={20}
          fillColor={iconColor}
          styles={{ marginLeft: "1rem", marginBottom: "-3px" }}
        />
      )}
      {icon && typeIcon === "qr" && (
        <span className="ms-3">
          <QrIcon size={20} fillColor={iconColor} />
        </span>
      )}
      {icon && typeIcon === "add" && <span className={styles.add}>+</span>}
    </button>
  );
};

export default Button;
