import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../components/Common/Button/Button";
import RequestSendIcon from "../../components/Icons/RequestSendIcon";
import Header from "../../components/Header/Header";
import FixedButton from "../../components/Common/FixedButton/FixedButton";
import SuccessCheckIcon from "../../components/Icons/SuccessCheckIcon";
import styles from "../Venue/reservation.module.css";
const ReservationSuccess = () => {
  const { t } = useTranslation();
  const { showUrl } = useParams();
  const showName = useSelector((state) => state.reservation.showName);
  const callCenter = useSelector((state) => state.reservation.callCenter);

  return (
    <>
      <Header showBack={false} title={showName} />
      <div
        className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}
      >
        {callCenter ? (
          <RequestSendIcon color={"var(--color-primary)"} size="135px" />
        ) : (
          <SuccessCheckIcon color="var(--color-primary)" size="111px" />
        )}
      </div>
      <div className="mt-5 pt-3 px-3">
        <div className="fw-bold text-start font-large">
          {callCenter ? t("requestSent") : t("reservationConfirmed")}
        </div>
        <div className="text-secondary fw-semibold font-normal text-start mt-3">
          {callCenter
            ? t("processingRequest")
            : t("reservationWasSuccessfullyConfirmed")}
        </div>
      </div>
      <FixedButton>
        <Button
          text={t("returnHome")}
          size="large"
          className="mt-2"
          onClick={() => (window.location.href = `/`)}
          icon={true}
          typeIcon="arrow"
          iconColor="#000"
        />
      </FixedButton>
    </>
  );
};

export default ReservationSuccess;
