import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSelector } from "react-redux";
import { getCookie } from "../../helpers/cookie";
import {
  skeletonRestaurantCard,
  skeletonRestaurantHeader,
} from "../../components/Skeletons/SkeletonRestaurantSelector";
import EmptyActivity from "../../components/Common/EmptyActivity/EmptyActivity";
import SolidChevron from "../../components/Icons/SolidChevron";
import { fetchShow } from "../../actions/appActions";
import WelletContext from "../../context/WelletContext";
import DataManager from "../../context/DataManager";
import { useNavigate } from "react-router-dom";
import ReservationTypeCard from "../../components/ReservationTypeCard/ReservationTypeCard";
import Footer from "../../components/Common/Footer/Footer";

import styles from "./Home.module.css";

const Home = () => {
  const { citySelected = null } = useContext(DataManager) || 2265;
  const navigate = useNavigate();
  const cityCookie = getCookie("selectedCity");
  const userCity = useSelector(
    (state) => state.associates?.user?.company?.country || "MX"
  );

  const observer = useRef();
  const pageRef = useRef(1);
  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const [restaurants, setRestaurants] = useState(null);
  const [loadMoreRestaurants, setLoadMoreRestaurants] = useState(false);
  const [loadingRestaurants, setLoadingRestaurants] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [cities, setCities] = useState(null);
  const [city, setCity] = useState(null);

  useEffect(() => {
    if (!cities) {
      getCities();
    }
  }, []);

  useEffect(() => {
    if (!restaurants && city) {
      getRestaurants(city.id);
    }
  }, [city]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getRestaurants = async (selectedCity, page = 1, pageSize = 15) => {
    setLoadingRestaurants(true);
    try {
      const result = await fetchShow(
        app.language,
        selectedCity,
        page,
        pageSize,
        welletContext
      );
      setRestaurants((prevData) => {
        if (prevData) {
          return [...prevData, ...result.shows];
        } else {
          return result.shows;
        }
      });
      setHasMore(result.shows.length >= pageSize);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRestaurants(false);
      setLoadMoreRestaurants(false);
    }
  };

  const getCities = async () => {
    try {
      const response = await welletContext.apis.tickets.get("/shows/cities");
      setCities(response.data);
      const defaultCity = response.data[0];
      if (citySelected) {
        setCity(response.data.find((c) => c.id === citySelected));
      } else if (cityCookie !== null && cityCookie?.trim() !== "") {
        const cookieCity = response.data.find(
          (c) => c.id === Number(cityCookie)
        );
        setCity(cookieCity ? cookieCity : defaultCity);
      } else {
        setCity(defaultCity);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const loadMore = () => {
    setLoadMoreRestaurants(true);
    pageRef.current += 1;
    getRestaurants(city?.id, pageRef.current);
  };

  const onRestaurantClick = (show) => {
    if (show.reservationTypes.length === 1) {
      navigate({
        pathname: show.url + "/restaurant-reservation",
      });
    } else {
      navigate({
        pathname: show.url,
        search: `?showId=${show.id}&showName=${show.name}`,
        state: { name: show.name },
      });
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingRestaurants) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  return (
    <>
      {loadingRestaurants && !restaurants ? (
        skeletonRestaurantHeader()
      ) : (
        <div className="page-container">
          <div className="page-content">
            <div className={`${styles.highlight} fade-up`}>
              <div
                className={
                  userCity === "US" || userCity === "ES"
                    ? "d-none"
                    : `d-flex mx-auto justify-content-center fw-semibold text-white align-items-center font-medium ${styles.cityContainer}`
                }
                style={{ width: city?.name?.length > 6 ? "70%" : "50%" }}
                onClick={() => navigate("/city-selector")}
              >
                {city?.name}

                <SolidChevron
                  size="15"
                  color="var(--color-primary)"
                  styles={{marginLeft: '.5rem'}}
                />
              </div>
              <div className="mt-4 env-bottom">
                {restaurants?.length > 0 ? (
                  restaurants.map((r, index) => (
                    <div className="mt-3" key={index}>
                      <ReservationTypeCard
                        lastElementRef={lastElementRef}
                        image={r.bannerImageUrl}
                        onHandleClick={() => onRestaurantClick(r)}
                      />
                    </div>
                  ))
                ) : !loadingRestaurants && restaurants?.length === 0 ? (
                  <div className="mt-3">
                    <EmptyActivity
                      title="Lo sentimos"
                      subtitle="En este momento no hay locales disponibles"
                    />
                  </div>
                ) : (
                  skeletonRestaurantCard()
                )}
                {loadMoreRestaurants && <div>{skeletonRestaurantCard()}</div>}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
