import React from "react";
import styles from "./reservation-type-icon.module.css";
import ChevronIconBig from "../Icons/chevronIconBig";
import GroupIcon from "../Icons/GroupIcon";
import RestaurantIcon from "../Icons/RestaurantIcon";
import AreaIcon from "../Icons/AreaIcon";

const ReservationTypeCardIcon = ({ title, type, onHandleClick }) => {
  const getIcon = () => {
    switch (type) {
      case "RESTAURANT":
        return <RestaurantIcon color={"var(--color-primary)"} size={36} />;
      case "AREA":
        return <AreaIcon color={"var(--color-primary)"} size={36} />;
      case "GROUP":
        return <GroupIcon color={"var(--color-primary)"} size={36} />;
    }
  };

  return (
    <div
      className={`${styles.restaurantCard} mt-2`}
      onClick={() => onHandleClick()}
    >
      <div style={{ marginLeft: "15px" }} className="col-auto p-0 ml-3">
        <span className={styles.containerImg}>{getIcon()}</span>
      </div>
      <div style={{ marginLeft: "15px" }} className="col p-0 ml-3">
        <div className="fw-semibold font-normal text-white ">{title}</div>
      </div>
      <div className={styles.ChevronIconBig}>
        <ChevronIconBig fillColor={"var(--color-primary)"} size={47} />
      </div>
    </div>
  );
};

export default ReservationTypeCardIcon;
