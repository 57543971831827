import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../Icons/InfoIcon";
import Money from "../../Money/Money";
import ReservationTicketInfo from "../../../screens/ReservationTicketInfo/ReservationTicketInfo";
import Modal from "../../Modal/Modal";
import { useSelector } from "react-redux";

const SummaryProducts = ({ reservationInfo}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productId, setProductId] = useState(false);
  const [prices, setPrices] = useState(null);
  const language = useSelector((state) => state.app.language);
  const toggleModal = (id) => {
    if (id) {
      const product = reservationInfo.products.find((p) => p.productId === id);
      const price = {
        consumption: product.consumptionAmount,
        priceExtraPax: product.amountPerExtraPax,
        price: product.amountPerUnit,
      };
      setPrices(price);
    }

    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div>
        <table className="w-100">
          <thead>
            <tr>
              <th className="font-smaller fw-bold">{t("items")}</th>
              <th className="font-smaller fw-bold text-center">
                {t("quantityAbbr")}
              </th>
              <th className="font-smaller fw-bold text-end">
                {t("price")}
              </th>
            </tr>
          </thead>
          <tbody className="font-small light py-1 ">
            {reservationInfo?.products.map((p, index) => (
              <React.Fragment key={index}>
                <tr className="font-small light py-1">
                  <td className="font-small light py-1 fw-bold">
                    {p.productName}
                    <span
                      style={{ top: "0", right: "0" }}
                      className="pl-2"
                      onClick={() => {
                        setProductId(p.productId);
                        toggleModal(p.productId);
                      }}
                    >
                      <InfoIcon
                        size="13px"
                        styles={{ marginTop: "-4px", marginLeft: "8px" }}
                      />
                    </span>
                  </td>
                  <td className="font-small text-center py-1">{p.quantity}</td>
                  <td className="font-small text-end">
                    <Money
                      value={p.totalAmountWithoutExtraPaxs}
                      symbolSize="14px"
                    />
                  </td>
                </tr>

                {p.extraPaxs && p.extraPaxs > 0 ? (
                  <tr className="py-1">
                    <td className="position-relative">{t("extraGuest")}</td>
                    <td className="font-small text-center py-1">
                      {p.extraPaxs}
                    </td>
                    <td className="font-small text-end  py-1">
                      <Money value={p.totalAmountExtraPaxs} symbolSize="14px" />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        animationClass="slide-left-right"
        fullScreen={true}
        hasBackground={true}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={productId}
          prices={prices}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          showAddButton={false}
        />
      </Modal>
    </>
  );
};

export default SummaryProducts;
