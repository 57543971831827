const initialProductsState = {
  showId: null,
  showName: null,
  products: [],
  performance: null,
  date: null,
  paxs: null,
  reservationType: null,
  smId: null,
  callCenter: null,
};

export default (state = initialProductsState, action) => {
  switch (action.type) {
    case "RESET_RESERVATION":
      return {
        ...state,
        showId: null,
        showName: null,
        products: [],
        performance: null,
        date: null,
        paxs: null,
        reservationType: null,
      };
    case "SET_RESERVATION_TYPE":
      return {
        ...state,
        reservationType: action.payload,
      };
    case "SM_ID":
      return { ...state, smId: action.payload };
    case "RESERVATION_INFO": {
      return {
        ...state,
        showId: action.payload.showId,
        showName: action.payload.showName,
        products: action.payload.products,
        performance: action.payload.performance,
        date: action.payload.date,
        paxs: action.payload.paxs,
        callCenter: action.payload.callCenter,
      };
    }
    case "RESERVATION_EXTRA_PAX": {
      let newProducts = [...state.products];

      newProducts.forEach((item, i) => {
        newProducts[i].extraPaxs = 0;
      });

      action.payload.forEach((item) => {
        const index = newProducts.findIndex((p) => p.id === item.id);

        if (item.specialType === "EXTRAPAX")
          newProducts[index].extraPaxs += item.quantity;
        else {
          if (index === -1 && item.quantity > 0) {
            newProducts.push({
              id: item.id,
              quantity: item.quantity,
              specialType: item.specialType,
            });
          } else if (index !== -1) {
            if (item.quantity === 0) newProducts.splice(index, 1);
            else newProducts[index].quantity = item.quantity;
          }
        }
      });

      return {
        ...state,
        products: newProducts,
      };
    }
    default:
      return state;
  }
};
