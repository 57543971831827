import React, { useState } from 'react';
import './input-form-border.css';
import '../../../css/icon-wellet.css';

const InputFormBorder = ({
  value,
  onChange,
  name,
  type,
  inputmode,
  error,
  style,
  icon,
  customClass = '',
  isDisabled = false,
  maxLength,
  textAlignment,
  fontSize,
  classInput = '',
  label,
  placeholder,
  forceLowecase = false,
  dataCheckout,
}) => {
  const [forceToShow, setForceToShow] = useState(false);

  return (
    <div
      className={`field-border-container ${error ? 'error' : ''} ${
        isDisabled ? 'disabled-input' : ''
      }`}
    >
      <div className={`field-border ${customClass}`}>
        {icon && <i className={`icon-wellet icon-wellet-${icon}`} />}
        <div className={`${icon ? 'input-icon' : ''}`}>
          <input
            name={name}
            type={forceToShow ? 'text' : type}
            inputMode={inputmode}
            value={forceLowecase ? value?.toLowerCase() : value}
            onChange={onChange}
            className={classInput}
            disabled={isDisabled}
            maxLength={maxLength}
            placeholder={placeholder}
            data-checkout={dataCheckout}
          />
          {label && (
            <span
              className={`floatingPlaceholder ${classInput}`}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </div>
      </div>
      {error && error.length > 0 && <div className='errorLabel'>{error}</div>}
    </div>
  );
};

export default InputFormBorder;
