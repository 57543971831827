import { Skeleton } from '@mui/material';

export const skeletonRestaurantHeader = () => {
  return (
    <>
      <div className='mt-4'>
        <div className='d-flex justify-content-center'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
            variant='rectangular'
            width={183}
            height={58}
          />
        </div>
        <div className='mt-4'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
            variant='rectangular'
            width={'100%'}
            height={293}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
            variant='rectangular'
            width={'100%'}
            height={293}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
            variant='rectangular'
            width={'100%'}
            height={293}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
            variant='rectangular'
            width={'100%'}
            height={293}
          />
        </div>
        <div className='mt-3'>
       
        </div>
      </div>
    </>
  );
};

export const skeletonRestaurantCard = () => {
  return (
    <>
      <div className='mt-5'>
        <Skeleton
          sx={{ bgcolor: 'grey.900', borderRadius: '20px' }}
          variant='rectangular'
          width={'100%'}
          height={112}
        />
      </div>
    </>
  );
};
