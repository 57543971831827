import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getStripeOnboardingRefreshLink } from "../../services/welletService";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

const StripeConnectRefresh = () => {
    const { token } = useParams();

    const getAndRefreshUrl = async () => {
        var decodedToken = decodeURIComponent(token);
        var newLink = await getStripeOnboardingRefreshLink(decodedToken);
        window.location.href = newLink;
    }

    const [newLink] = useState(() => {
        getAndRefreshUrl();
    });

    return <LoadingScreen />

}

export default StripeConnectRefresh;