const initialState = {
  language: null,
  currencies: [],
  ctCookieTime: null,
  status: 'LOADING'
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "GET_SETUP":
      return {
        ...state,
        ctCookieTime: action.payload.ctCookieTime,
        currencies: action.payload.currencies,
        status: 'READY'
      };
    default:
      return state;
  }
}
