import React from "react";

const SolidChevron = ({
  size = "22px",
  color = "fff",
  styles,
  className,
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      style={styles}
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1048 3500 c-71 -38 -101 -107 -79 -179 16 -55 1398 -1644 1465
-1685 41 -26 58 -30 120 -31 132 0 84 -48 868 853 387 444 711 823 721 842 23
43 18 111 -10 149 -11 14 -37 36 -59 48 l-39 23 -1475 -1 c-1439 0 -1476 0
-1512 -19z"
        />
      </g>
    </svg>
  );
};

export default SolidChevron;
