import { useNavigate } from "react-router-dom";
import ChevronIcon from "../Icons/ChevronIcon";
import "./header.css";

const Header = ({
  title,
  showBack = true,
  pathName,
  pathSearch,
  pathState,
  onClick,
  logo,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (pathName) {
      navigate.push({
        pathname: pathName,
        search: pathSearch,
        state: pathState,
      });
    } else if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={`header app w-100`}>
      <div className="row">
        {showBack ? (
          <div className="col-auto back p-0">
            <button
              type="button"
              onClick={handleGoBack}
              className="back-button font-medium"
              aria-label="Close"
            >
              <ChevronIcon
                fillColor="var(--color-primary)"
                size={"26px"}
                styles={{
                  transform: "rotate(180deg)",
                  marginTop: "-1px",
                  marginLeft: "-2px",
                }}
              />
            </button>
          </div>
        ) : (
          <div
            className="col-auto p-0 back"
            style={{ minWidth: "55px", minHeight: "55px" }}
          ></div>
        )}

        <div className="row col header-title center fw-bold my-auto justify-content-center align-items-center">
          {logo && (
            <div className="col-2 logo p-0">
              <img src={logo} alt="logo-wellet" />
            </div>
          )}

          <div className="p-0 col-auto">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
