import ErrorIconRoutes from "../../components/Icons/ErrorIconRoutes";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "pages.pageError" });
  return (
    <>
      <div className="text-center mt-5">
        <h5 className="mb-5">{t("title")}</h5>
        <ErrorIconRoutes />
      </div>
      <div className="mt-5 pt-3 px-3">
        <div className="fw-bold text-start font-large">{t("subtitle")}</div>
        <div className="text-secondary fw-semibold font-normal text-start mt-3">
          {t("text")}
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
