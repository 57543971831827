import React from "react";

const AreaIcon = ({ size, color, styles }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 32"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M19.615 6.11971C20.2437 5.76304 20.7368 5.20824 21.017 4.54189C21.2973 3.87554 21.3491 3.13514 21.1643 2.43626C20.9795 1.73738 20.5686 1.11934 19.9955 0.678616C19.4225 0.237887 18.7197 -0.000732218 17.9968 1.68786e-06C17.2739 0.000735594 16.5716 0.240782 15.9995 0.682673C15.4274 1.12456 15.0177 1.74343 14.8343 2.44269C14.6509 3.14194 14.7042 3.88223 14.9859 4.54801C15.2675 5.21379 15.7616 5.76759 16.3911 6.12298L16.3748 6.15244C15.3029 8.38947 13.8137 11.5936 11.5145 12.7964C9.62606 13.7832 6.9161 13.2874 4.90327 12.9175C4.87098 12.4598 4.71105 12.0203 4.44163 11.6489C4.17222 11.2775 3.80409 10.9891 3.37904 10.8163C2.95398 10.6435 2.489 10.5933 2.03686 10.6714C1.58472 10.7495 1.16352 10.9528 0.821056 11.2581C0.478589 11.5635 0.228563 11.9587 0.0993477 12.399C-0.0298675 12.8393 -0.0330989 13.3069 0.0900201 13.7489C0.213139 14.1909 0.457679 14.5896 0.795895 14.8997C1.13411 15.2097 1.55246 15.4188 2.00348 15.5031L6.82773 27.9565C7.1855 28.8797 7.81407 29.6732 8.63093 30.2328C9.44779 30.7924 10.4148 31.0918 11.4049 31.0919H24.5947C25.5848 31.0918 26.5518 30.7924 27.3686 30.2328C28.1855 29.6732 28.8141 28.8797 29.1718 27.9565L33.9944 15.5031C34.4426 15.4197 34.8587 15.2131 35.1961 14.9066C35.5335 14.6 35.7788 14.2055 35.9047 13.7674C36.0305 13.3292 36.0318 12.8646 35.9085 12.4258C35.7851 11.9869 35.542 11.5911 35.2064 11.2826C34.8707 10.9741 34.4558 10.7652 34.0081 10.6792C33.5604 10.5933 33.0976 10.6337 32.6716 10.796C32.2456 10.9582 31.8732 11.2359 31.5961 11.5979C31.319 11.9599 31.1482 12.3919 31.1028 12.8455C29.0425 13.1123 26.3866 13.4526 24.485 12.4593C22.2316 11.2811 20.7212 8.29782 19.615 6.11971Z"
        fill={color}
      />
    </svg>
  );
};

export default AreaIcon;
