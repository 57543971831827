import React from "react";
import LocationDotIcon from "../../Icons/LocationDotIcon";
import InstagramIcon from "../../Icons/InstagramIcon";
import FacebookIcon from "../../Icons/FacebookIcon";
import { useTranslation } from "react-i18next";
import "./footer.css";

const Footer = ({ show }) => {
  const { t } = useTranslation();

  const openGoogleMaps = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${show.latitude},${show.longitude}`;
    window.open(googleMapsUrl, "_blank");
  };

  const getNetworkIcon = (network) => {
    switch (network) {
      case "INSTAGRAM":
        return (
          <InstagramIcon
            size={24}
            color="#fff"
            styles={{ marginBottom: "-3px" }}
          />
        );
      case "FACEBOOK":
        return (
          <FacebookIcon
            size={24}
            color="#fff"
            styles={{ marginBottom: "-3px" }}
          />
        );
    }
  };

  return (
    <footer className="mt-5 pt-5 env-bottom text-center font-small">
      <div className="logo">
        {show ? (
          <img src={show.logoUrl} alt={show.name} />
        ) : (
          <img src="https://cdn1.wellet.dev/96d014c4-5ed9-4da3-80db-61f9231e9898"></img>
        )}
      </div>

      {show && (
        <div className="px-5 mb-4" onClick={openGoogleMaps}>
          <div className="row">
            <div className="col-auto p-0">
              <LocationDotIcon
                size={20}
                color="#fff"
                styles={{ marginBottom: "-2px" }}
              />
            </div>
            <div className="col p-0">
              {show?.address}, {show.postalCode}, {show.city.nameLong}
            </div>
          </div>
        </div>
      )}

      {show ? (
        <div>
          {show.socialNetworks.map((s) => (
            <a href={s.value} target="_blank" className="px-2">
              {getNetworkIcon(s.socialNetwork)}
            </a>
          ))}
        </div>
      ) : (
        <div>
          <a
            href="https://www.instagram.com/gruporosanegra?igshid=llbd8h5v936p"
            target="_blank"
            className="px-2"
          >
            {getNetworkIcon("INSTAGRAM")}
          </a>
          <a
            href="https://www.facebook.com/gruporosanegra.mexico"
            target="_blank"
            className="px-2"
          >
            {getNetworkIcon("FACEBOOK")}
          </a>
        </div>
      )}
      <div className="font-smaller mt-4">
        © {t("allRightsReserved")} |{" "}
        <a
          href="https://gruporosanegra.com.mx/es/aviso-de-privacidad"
          target="_blank"
          className="text-white"
        >
          {t("privacyPolicy")}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
