import { useEffect, useState } from "react";
import {
  getReservation,
  previewReservation,
} from "../../services/welletService";
import { format } from "date-fns";
import { getLocale } from "../../helpers/dates";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import InfoIcon from "../../components/Icons/InfoIcon";
import Money from "../../components/Money/Money";
import MercadoPagoForm from "../../components/MercadoPago/MercadoPagoForm";
import StripePaymentForm from "../../components/Stripe/StripePaymentForm";
import Modal from "../../components/Modal/Modal";
import ReservationTicketInfo from "../ReservationTicketInfo/ReservationTicketInfo";
import LinkCases from "../LinkCases/LinkCases";
import { useTranslation } from "react-i18next";
import "./reservation.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import SummaryProducts from "../../components/ReservationCheck/Summary/SummaryProducts";
import { setLanguage } from "../../actions/appActions";

const gateways = {
  MERCADOPAGO: MercadoPagoForm,
  STRIPE: StripePaymentForm,
};

const PaymentReservation = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.language);
  const [locale, setLocale] = useState(getLocale(language));
  const reservationData = useSelector((state) => state.reservation);
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExtraProduct, setIsExtraProduct] = useState(false);
  const [error, setError] = useState(null);
  const [reservationDate, setReservationDate] = useState(reservationData.date);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const dispatch = useDispatch();

  const getReservationInfo = async () => {
    try {
      const result = await getReservation(token);
      if (result.show && result.show.name) {
        document.title = result.show.name;
      }
      setReservation(result);
      setReservationDate(new Date(result.reservationDateLocalTime));
      i18n.changeLanguage(result.language).then((t) => {
        setLoading(false);
      });
      dispatch({ type: "SET_LANGUAGE", payload: result.language });

      setLoading(false);
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.errorCode === "RESERVATION_ALREADY_CONFIRMED") {
          navigate({
            pathname: "/success",
            search: createSearchParams({ token: token }).toString(),
          });
          return;
        }

        setError(e.response.data.errorCode);
      } else setError("API_ERROR");

      setLoading(false);
    }
  };

  const getPreview = async () => {
    try {
      const result = await previewReservation(
        reservationData.showId,
        reservationData.date,
        reservationData.products,
        language
      );

      setReservation(result);
      setLoading(false);
    } catch (e) {
      setError("API_ERROR");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) getReservationInfo();
    else {
      getPreview();
    }
  }, []);

  const handleProductInfo = (productId) => {
    setProductId(productId);
    setIsModalOpen(!isModalOpen);
  };

  const GatewayPaymentForm = reservation
    ? gateways[reservation.gateway.name]
    : null;

  return loading ? (
    <LoadingScreen />
  ) : error && error.length > 0 ? (
    <LinkCases error={error} />
  ) : (
    <>
      <div className="reservation payment">
        <div className="payment-section">
          <div className="row">
            <div className="col">
              <div className="fw-bold">
                <div className="show-name">{reservation.show.name}</div>
                <div className="bold text-lighter-gray">
                  <div className="mt-1">
                    {reservation?.kidsPaxs > 0 ? (
                      <>
                        {t("adult", {
                          count: reservation?.paxs - reservation?.kidsPaxs,
                        })}{" "}
                        + {t("kid", { count: reservation?.kidsPaxs })}
                      </>
                    ) : (
                      t("person", { count: reservation.paxs })
                    )}
                  </div>
                  <div className="mt-1 sentences-capitalize">
                    {language === "es"
                      ? format(reservationDate, "EEEE, d 'de' MMMM", {
                          language,
                        })
                      : format(reservationDate, "EEEE, d MMMM", { language })}
                  </div>
                  {reservation.performance ? (
                    <div className="mt-1">{reservation.performance} hr</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 fw-semibold">
            <SummaryProducts reservationInfo={reservation} />
          </div>

          {reservation.consumptionAmount > 0 && (
            <div className="mt-4">
              <div className="consumption ">
                {t("consumptionIncluded", {
                  consumption: "$" + reservation.consumptionAmount,
                })}
              </div>
            </div>
          )}
        </div>
        <div className="payment-section">
          <div className="text-secondary text-medium fw-semibold">
            {t("form.bookingHolder")}
          </div>
          <div className="font-medium fw-bold mt-1 ">
            {reservation.customerName}
          </div>
          {reservation.reservationOccasion &&
            reservation.reservationOccasion.length > 0 && (
              <>
                <div className=" text-medium fw-semibold mt-3">
                  {t("occasion")}
                </div>
                <div className="font-small">
                  {reservation.reservationOccasion}
                </div>
              </>
            )}
          {reservation.reservationComments &&
            reservation.reservationComments.length > 0 && (
              <>
                <div className="text-secondary text-medium fw-semibold mt-3">
                  {t("specialRequests")}
                </div>
                <div className="font-small">
                  {reservation.reservationComments}
                </div>
              </>
            )}
        </div>

        <div className="payment-section">
          <div className="fw-bold">{t("summary")}</div>
          <div className="font-small text-lighter-gray">
            {reservation.reservationType === "AREA" ? (
              <div className="row mt-2">
                <div className="col pr-0">
                  {t("productsInfo.numberOfPeople")}:
                </div>
                <div className="col-auto">
                  {t("upToGuest", {
                    count: reservation.paxs - reservation.kidsPaxs,
                  })}
                </div>
              </div>
            ) : (
              <div className="row mt-2">
                <div className="col pr-0">
                  {t("productsInfo.numberOfAdults")}:
                </div>
                <div className="col-auto">
                  {reservation.paxs - reservation.kidsPaxs}
                </div>
              </div>
            )}
            {reservation.kidsPaxs > 0 && (
              <div className="row mt-2">
                <div className="col pr-0">
                  {t("productsInfo.numberOfChildren")}:
                </div>
                <div className="col-auto">{reservation.kidsPaxs}</div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col">{t("productsInfo.priceDetailsFor")}:</div>
              <div className="col-auto">{reservation.items} items</div>
            </div>
            <div className="row mt-2">
              <div className="col">{t("subTotal")}:</div>
              <div className="col-auto">
                <Money value={reservation.subTotalAmount} />
              </div>
            </div>
            {reservation.tipAmount > 0 ? (
              <div className="row mt-2">
                <div className="col">{t("productsInfo.tip")}:</div>
                <div className="col-auto">
                  <Money value={reservation.tipAmount} />
                </div>
              </div>
            ) : null}
            {reservation.taxes > 0 ? (
              <div className="row mt-2">
                <div className="col">{t("productsInfo.taxes")}:</div>
                <div className="col-auto">
                  <Money value={reservation.taxes} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row fw-bold mt-2">
            <div className="col font-small">{t("total")}:</div>
            <div className="col-auto">
              <Money value={reservation.totalAmount} />
            </div>
          </div>
          {reservation.remainingAmount > 0 ? (
            <>
              <div className="row mt-2 fw-bold">
                <div className="col">{t("productsInfo.depositGuarantee")}:</div>
                <div className="col-auto">
                  <Money value={reservation.depositAmount} />
                </div>
              </div>
              <div className="row mt-2 fw-bold">
                <div className="col">{t("productsInfo.balanceToBePaid")}:</div>
                <div className="col-auto">
                  <Money value={reservation.remainingAmount} />
                </div>
              </div>
            </>
          ) : null}
        </div>

        {reservation.paymentDetails?.length > 0 ? (
          <div className="payment-section">
            <div className="fw-bold ">{t("myPayments")}</div>
            {reservation.paymentDetails.map((payment, i) => (
              <div className="row mt-3" key={i}>
                <div className="col">
                  <div className="fw-bold ">
                    {t("payment")} #{payment.chargeId}
                  </div>
                  <div className="text-secondary font-small mt-1">
                    <div>
                      <span className="text-capitalize">{payment.brand}</span>
                      (xxxx xxxx xxxx {payment.last4})
                    </div>
                    <div>
                      {format(
                        new Date(payment.paymentLocalDate),
                        "PPP HH:mm 'hr'",
                        {
                          locale,
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-auto payment-amount">
                  -<Money value={payment.amount} />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {reservation.remainingAmount > 0 ? (
          <div className="mt-4">
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-4 pe-1">
                    <div className="balance-box">
                      <div>
                        <div>{t("total")}</div>
                        <div className="fw-bold">
                          <Money value={reservation.totalAmount} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 px-2">
                    <div className="balance-box">
                      <div>
                        <div className="fw-bold">{t("deposit")}</div>
                        <div className="fw-bold">
                          <Money value={reservation.depositAmount} />
                        </div>
                        <div style={{ fontSize: "11px" }}>
                          {t("productsInfo.payUponConfirmation")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 ps-1">
                    <div className="balance-box">
                      <div>
                        <div className="fw-bold">{t("balance")}</div>
                        <div className="fw-bold">
                          <Money value={reservation.remainingAmount} />
                        </div>
                        <div style={{ fontSize: "11px" }}>
                          {t("productsInfo.payUponArrival")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="fw-bold mt-4">{t("informationForPayment")}</div>
        {reservation.remainingDepositAmount > 0 ? (
          <div className="mt-3">
            <div className="row">
              <div className="col font-small">{t("remainingBalance")}:</div>
              <div className="col-auto font-normal fw-bold">
                <Money value={reservation.remainingDepositAmount} />
              </div>
            </div>
            <div className="font-small text-secondary text-center mt-4 px-3">
              {reservation.parentReservationReferenceCode
                ? t("expirationModifyInfoText", {
                    date: format(
                      reservation.expirationLocalDate,
                      "PPPP HH:mm 'hr'",
                      { locale }
                    ),
                  })
                : t("expirationInfoText", {
                    date: format(
                      reservation.expirationLocalDate,
                      "PPPP HH:mm 'hr'",
                      { locale }
                    ),
                  })}
            </div>
          </div>
        ) : null}

        {GatewayPaymentForm && (
          <div className="mt-5">
            <GatewayPaymentForm
              token={token}
              reservation={reservation}
              showId={reservation.show.id}
            />
          </div>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => handleProductInfo()}
        animationClass="slide-left-right"
        fullScreen={true}
        hasBackground={false}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={productId}
          isExtraProduct={isExtraProduct}
        />
      </Modal>
    </>
  );
};

export default PaymentReservation;
