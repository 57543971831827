import React from "react";

const GroupIcon = ({ size, color, styles }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 40 29"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M19.9843 3.08594C16.9488 3.08594 14.3289 5.19176 14.3289 9.23967C14.3289 11.8853 15.5664 14.5855 17.1379 16.2464C17.7508 17.9061 16.6382 19.0878 16.4082 19.1768C13.2314 20.3584 9.51172 22.4927 9.51172 24.6258V25.442C9.51172 28.3474 14.9652 29.0011 20.0193 29.0011C25.0793 29.0011 30.4907 28.3474 30.4907 25.442V24.6258C30.4907 22.4286 26.7453 20.3133 23.4121 19.1756C23.2603 19.1246 22.3018 18.0924 22.9019 16.2096H22.8657C24.4301 14.5487 25.7845 11.8746 25.7845 9.23967C25.7845 5.19176 23.0163 3.08594 19.9819 3.08594H19.9843Z"
        fill={color}
      />
      <path
        d="M25.3206 3.12586C26.1369 1.078 27.9798 0 30.0916 0C32.961 0 35.3042 1.97227 35.3042 5.70633C35.3042 8.12981 34.2882 10.6068 32.8083 12.1371C32.2398 13.8734 33.1419 14.0896 33.2852 14.1362C36.4376 15.1839 40.0012 17.1643 40.0012 19.1878V19.9146C40.0012 22.1218 36.5222 22.8986 32.6251 23.1126C31.5187 20.2635 27.6474 18.4063 25.1738 17.4795C26.7266 15.6328 28.2206 12.7663 28.2206 9.62994C28.2206 6.69247 27.0343 4.52599 25.3206 3.12586Z"
        fill={color}
      />
      <path
        d="M14.6774 3.11067C13.8612 1.07277 12.0187 0 9.90716 0C7.0382 0 4.69534 1.96269 4.69534 5.6786C4.69534 8.0903 5.71117 10.5552 7.19086 12.0782C7.75926 13.8059 6.85735 14.0212 6.71407 14.0675C3.56208 15.1101 -0.000933647 17.0809 -0.000933647 19.0945V19.8178C-0.000933647 22.0143 3.47753 22.7873 7.37406 23.0002C8.48031 20.165 12.351 18.3169 14.8242 17.3945C13.2717 15.5568 11.7779 12.7042 11.7779 9.58314C11.7779 6.65994 12.964 4.504 14.6774 3.11067Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupIcon;
