export default (
  state = {
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case 'SET_EXTRA_PRODUCT':
      return {
        ...state,
        items: action.payload,
      };
    case 'ADD_EXTRA_PRODUCT':
      return {
        ...state,
        items: [...state.items, action.payload],
      };

      case 'REMOVE_EXTRA_PRODUCT':
        const indexToRemove = state.items.findIndex((p) => p.id === action.payload.id);
        if (indexToRemove !== -1) {
          const newList = [
            ...state.items.slice(0, indexToRemove),
            ...state.items.slice(indexToRemove + 1)
          ];
          return {
            ...state,
            items: newList,
          };
        }
        default:
          return state;
  }
};
