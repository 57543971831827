import React, { useEffect, useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';  
import { useSelector } from 'react-redux';

function convertToStripeAmount (amount) {
  return parseInt(amount.toFixed(2)) * 100
}

const StripePaymentForm = ({reservation, ...props}) => {
  const amount = reservation ? convertToStripeAmount(reservation.remainingDepositAmount) : 0;
  const [stripeReady, setStripeReady] = useState(false);

  const options = {
    mode: 'payment',
    amount,
    currency: reservation.currency.toLowerCase(),
    paymentMethodCreation: 'manual',
    locale: reservation.language,
    appearance: { // https://docs.stripe.com/elements/appearance-api#theme , https://docs.stripe.com/payments/payment-element
      theme: 'night',
      variables: { 
        colorText: '#ffffff',
        colorBackground: '#000000',
        colorDanger: '#f80005',
      }
    }
  };

  if(reservation.gateway.accountId) {
    options.on_behalf_of = reservation.gateway.accountId;
  }

  useEffect(() => {
    if(!reservation) return;
    if(window.stripePromise === undefined) {
      let options = {
        locale: reservation.language
      };
      // if(reservation.gateway.accountId) {
      //   options.stripeAccount = reservation.gateway.accountId;
      // }
      window.stripePromise = loadStripe(reservation.gateway.publicKey, options);
      setStripeReady(true);
    }
  }, [reservation])

  if(!stripeReady && !window.stripePromise) return null;

  return (
    <Elements stripe={window.stripePromise} options={options}>
      <StripeCheckoutForm reservation={reservation} {...props}/>
    </Elements>
  );
};

export default StripePaymentForm;
