import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getPaidReservation } from "../../services/welletService";
import { getLocale } from "../../helpers/dates";
import Money from "../../components/Money/Money";
import SummaryBox from "../../components/SummaryBox/SummaryBox";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import "./payment-confirm.css";

const PaymentDetails = () => {
  const { t, i18n } = useTranslation();
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reservationDate, setReservationDate] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [locale, setLocale] = useState(null);

  const getReservationInfo = async () => {
    try {
      const result = await getPaidReservation(token);
      if(result.show && result.show.name) {
        document.title = result.show.name;
      }
      setReservation(result);
      setReservationDate(new Date(result.reservationDateLocalTime));
      i18n.changeLanguage(result.language);
      setLocale(getLocale(result.language));
    } catch (error) {
      setError(error.response.data.errorCode);
    }
    setLoading(false);
  };

  const renderProduct = (product, index) => {
    return (
      <div key={index}>
        <div className="row flex-nowrap">
          <div className="col text-truncate">
            <div
              className="text-truncate"
              style={{
                paddingRight: "25px",
                display: "inline-block",
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {product.productName} x {product.quantity}
            </div>
          </div>
        </div>
        {product.extraPaxs > 0 ? (
          <div className="row flex-nowrap mt-1">
            <div className="col text-truncate">
              <div
                className="text-truncate"
                style={{
                  paddingRight: "25px",
                  display: "inline-block",
                  maxWidth: "100%",
                  position: "relative",
                }}
              >
                {product.productName} - {t("extraGuests")} x {product.extraPaxs}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const getCountry = (typeStr) => {
    switch (typeStr) {
      case "SUPPORT_PHONE_US":
        return "USA";
      case "SUPPORT_PHONE_MX":
        return "Mexico";
      case "WHATSAPP":
        return "Whatsapp";
      default:
        return null;
    }
  };

  useEffect(() => {
    getReservationInfo();
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="mt-4">
      <div className="text-center fw-bold">
        {t("paymentConfirm.reservationDetails")} {reservation.referenceCode}
      </div>
      <div className="payment-summary mt-4">
        <div className="payment-section">
          <div className="row">
            <div className="col-auto">
              <div className="show-logo">
                <img
                  src={reservation.show.logoImageUrl}
                  title={reservation.show.name}
                />
              </div>
            </div>
            <div className="col ps-0">
              <div className="fw-bold">
                <div className="show-name">{reservation.show.name}</div>
                <div className="mt-2">
                  {reservation.paxs}{" "}
                  {reservation.paxs > 1 ? t("people") : t("person")}
                  <div className="mt-1">
                    {locale.code === "en-US"
                      ? format(reservationDate, "EEEE, d MMMM", { locale })
                      : format(reservationDate, "EEEE, d 'de' MMMM", {
                          locale,
                        })}
                  </div>
                  <div className="mt-1">
                    {format(reservationDate, "HH:mm 'hr'", { locale })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mt-4 fw-semibold">
              <div className="text-secondary text-medium mb-3">
                {t("yourBooking")}
              </div>
              {reservation.products.map((p, i) => renderProduct(p, i))}
            </div>
            {reservation?.consumptionAmount > 0 && (
              <div className="consumption mt-4">
                {t("consumptionIncluded", {
                  consumption: "$" + reservation?.consumptionAmount,
                })}
              </div>
            )}
          </div>
        </div>
        <div className="payment-section">
          <div className="fw-semibold font-small text-secondary">
            {t("paymentConfirm.reservationCode")}
          </div>
          <div className="font-normal fw-bold mt-1">
            {reservation.referenceCode}
          </div>
          <div className="fw-semibold font-small text-secondary mt-3">
            {t("form.bookingHolder")}
          </div>
          <div className="font-normal fw-bold mt-1">
            {reservation.customerName}
          </div>
          {reservation.reservationOccasion && (
            <>
              <div className="fw-semibold font-small text-secondary mt-3">
                {t("occasion")}
              </div>
              <div className="mt-1">{reservation.reservationOccasion}</div>
            </>
          )}

          {reservation.reservationComments && (
            <>
              <div className="fw-semibold font-small text-secondary mt-3">
                {t("specialRequests")}
              </div>
              <div className="mt-1">{reservation.reservationComments}</div>
            </>
          )}
        </div>

        <div className="payment-section">
          <div className="fw-bold">{t("summary")}</div>
          <div className="font-small">
            {reservation.reservationType === "AREA" ? (
              <div className="row mt-2 text-lighter-gray">
                <div className="col pr-0">
                  {t("productsInfo.numberOfPeople")}:
                </div>
                <div className="col-auto">
                  {t("upToGuest", {
                    count: reservation.paxs - reservation.kidsPaxs,
                  })}
                </div>
              </div>
            ) : (
              <div className="row mt-2 text-lighter-gray">
                <div className="col pr-0">
                  {t("productsInfo.numberOfAdults")}:
                </div>
                <div className="col-auto">
                  {reservation.paxs - reservation.kidsPaxs}
                </div>
              </div>
            )}
            {reservation.kidsPaxs > 0 && (
              <div className="row mt-2 text-lighter-gray">
                <div className="col pr-0">
                  {t("productsInfo.numberOfChildren")}:
                </div>
                <div className="col-auto">{reservation.kidsPaxs}</div>
              </div>
            )}

            <div className="row mt-2 text-lighter-gray">
              <div className="col">{t("productsInfo.priceDetailsFor")}:</div>
              <div className="col-auto">{reservation.items} items</div>
            </div>

            <div className="row mt-2 text-lighter-gray">
              <div className="col">Sub total:</div>
              <div className="col-auto">
                <Money value={reservation.subTotalAmount} />
              </div>
            </div>
            {reservation.tipAmount > 0 ? (
              <div className="row mt-2">
                <div className="col">{t("productsInfo.tip")}:</div>
                <div className="col-auto">
                  <Money value={reservation.tipAmount} />
                </div>
              </div>
            ) : null}
            {reservation.taxes > 0 ? (
              <div className="row mt-2">
                <div className="col">{t("productsInfo.taxes")}:</div>
                <div className="col-auto">
                  <Money value={reservation.taxes} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row mt-2 fw-bold">
            <div className="col font-small">Total:</div>
            <div className="col-auto">
              <Money value={reservation.totalAmount} />
            </div>
          </div>
          {reservation.remainingAmount > 0 && (
            <>
              <div className="row mt-1 fw-bold">
                <div className="col">{t("productsInfo.depositGuarantee")}:</div>
                <div className="col-auto">
                  <Money value={reservation.depositAmount} />
                </div>
              </div>
              <div className="row mt-1 fw-bold">
                <div className="col">{t("productsInfo.balanceToBePaid")}</div>
                <div className="col-auto">
                  <Money value={reservation.remainingAmount} />
                </div>
              </div>
            </>
          )}

          {reservation.remainingAmount > 0 && (
            <div className={`row justify-content-center my-4`}>
              <div className="col-4 px-1">
                <SummaryBox title="Total" value={reservation.totalAmount} />
              </div>
              <div className="col-4 p-0">
                <SummaryBox
                  title={t("deposit")}
                  value={reservation.depositAmount}
                  subtitle={t("productsInfo.payUponConfirmation")}
                />
              </div>
              <div className="col-4 px-1">
                <SummaryBox
                  title="Balance"
                  value={reservation.remainingAmount}
                  subtitle={t("productsInfo.payUponArrival")}
                />
              </div>
            </div>
          )}
        </div>

        <div className="payment-section">
          <div className="fw-bold">{t("paymentInformation")}</div>
          {reservation.paymentDetails.map((payment, i) => (
            <div className="row font-small mt-3" key={payment.chargeId}>
              <div className="col">
                <div className="fw-bold ">
                  {t("payment")} #{payment.chargeId}
                </div>
                <div className="text-secondary font-tiny mt-1">
                  <div>
                    <span className="text-capitalize">{payment.brand}</span>
                    (XXXX XXXX XXXX {payment.last4})
                  </div>
                  <div>
                    {format(new Date(payment.paymentLocalDate), "PPP HH:mm", {
                      locale,
                    })}
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <Money value={payment.amount} />
              </div>
            </div>
          ))}
        </div>

        {reservation.show.termsAndConditions &&
          reservation.show.termsAndConditions.length > 0 && (
            <div className="payment-section">
              <div className="fw-bold">{t("form.terms2")}</div>
              <div
                className="terms-and-conditions font-small"
                dangerouslySetInnerHTML={{
                  __html: reservation.show.termsAndConditions,
                }}
              ></div>
            </div>
          )}
        {reservation.show.supportWeekDays &&
        (reservation.show.supportWeekDays.length > 0 ||
          reservation.show.showContacts.length) > 0 ? (
          <div className="payment-section support font-small text-center border-none">
            <div className="mb-3 support-title ">
              {t("paymentConfirm.reservationAssistance")}
            </div>

            {reservation.show.showContacts.map((item, i) => {
              const formattedNumber = formatPhoneNumberIntl(item.value);
              return (
                <div className="fw-bold mt-1" key={i}>
                  {item.typeStr === "EMAIL" ? (
                    <div>{item.value}</div>
                  ) : (
                    <>
                      {getCountry(item.typeStr)}: {formattedNumber}
                    </>
                  )}
                </div>
              );
            })}

            {reservation.show.showContacts.length > 0 && (
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: reservation.show.supportWeekDays,
                }}
              ></div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentDetails;
