import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const languages = ["en", "es"];

const initTranslations = () => {
  return i18next
    .use(HttpApi)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      load: "languageOnly",
      detection: {
        lookupQuerystring: "lang",
        order: ["querystring", "navigator"],
      },
      debug: false,
      fallbackLng: "en",
      whitelist: languages,
      backend: {
        loadPath: "/translations/{{lng}}/{{ns}}.json",
        allowMultiLoading: false,
        crossDomain: true,
        withCredentials: false,
        queryStringParams: { v: process.env.REACT_APP_TIMESTAMP },
      },
    });
};

export default initTranslations;
