const ErrorIconRoutes = () => {
  return(<svg
    width="136"
    height="118"
    viewBox="0 0 136 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.173 22.7738C99.6577 17.5518 93.7058 13.7966 87.0708 11.9834C80.4358 10.1703 73.4161 10.3807 66.8998 12.5879C60.3836 14.7952 54.6638 18.9002 50.4643 24.3834C46.2647 29.8666 43.7742 36.4816 43.3079 43.3912C43.1086 46.3576 40.557 48.5985 37.6126 48.3947C34.6687 48.191 32.445 45.619 32.6473 42.6534C33.1411 35.3705 35.3569 28.3123 39.1097 22.0676C42.8625 15.823 48.0434 10.5731 54.2202 6.75609C60.3969 2.93907 67.3903 0.665631 74.6168 0.125486C81.8432 -0.414658 89.0931 0.794161 95.7614 3.65107C102.43 6.50797 108.323 10.9301 112.95 16.5484C117.576 22.1667 120.802 28.8183 122.357 35.948C123.912 43.0777 123.753 50.4787 121.891 57.5335C120.029 64.5883 116.519 71.0922 111.654 76.5024L134.685 103.131C136.624 105.373 136.392 108.774 134.167 110.727C131.941 112.681 128.565 112.447 126.625 110.205L103.595 83.5771C95.1683 89.3808 85.0432 92.1492 74.8597 91.4339C71.9144 91.23 69.6908 88.6595 69.8931 85.6924C70.0954 82.7253 72.6471 80.4853 75.5924 80.6891C82.4481 81.1631 89.2894 79.5787 95.2513 76.136C101.213 72.6933 106.028 67.5471 109.086 61.348C112.145 55.149 113.31 48.1755 112.435 41.3093C111.559 34.4431 108.682 27.9926 104.168 22.7734"
      fill="#1D9BF0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.3414 118C48.6508 118 62.6828 103.864 62.6828 86.4271C62.6828 68.9899 48.6508 54.8542 31.3414 54.8542C14.032 54.8542 0 68.9899 0 86.4271C0 103.864 14.032 118 31.3414 118ZM31.3395 81.1662L23.5064 73.2733C22.0636 71.8195 19.7239 71.8197 18.2813 73.2738C16.8394 74.7272 16.8398 77.0829 18.2822 78.5358L26.1166 86.4271L18.2822 94.3184C16.8398 95.7713 16.8394 98.127 18.2813 99.5804C19.7239 101.034 22.0636 101.035 23.5064 99.5808L31.3395 91.688L39.1761 99.5816C40.6191 101.035 42.9587 101.035 44.4012 99.5806C45.8433 98.1271 45.8428 95.7712 44.4003 94.3182L36.5662 86.4271L44.4003 78.536C45.8428 77.083 45.8433 74.7271 44.4012 73.2736C42.9587 71.8196 40.6191 71.8191 39.1761 73.2727L31.3395 81.1662Z"
      fill="#1D9BF0"
    />
  </svg>)
}

export default ErrorIconRoutes;