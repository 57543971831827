// App.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WelletContextProvider } from "./context/WelletContext";
import ContentApp from "./components/ContentApp/ContentApp";
import PaymentReservation from "./screens/PaymentReservation/PaymentReservation";
import PaymentConfirm from "./screens/PaymentConfirm/PaymentConfirm";
import PaymentDetails from "./screens/PaymentConfirm/PaymentDetails";
import Venue from "./screens/Venue/Venue";
import Reservation from "./screens/Venue/Reservation";
import ReservationCheck from "./screens/Venue/ReservationCheck/ReservationCheck";
import ReservationSuccess from "./screens/ReservationSuccess/ReservationSuccess";
import { getCookie, setCookie } from "./helpers/cookie";
import ReservationVip from "./screens/Venue/ReservationVip";
import ReservationVipExtra from "./screens/Venue/ReservationVipExtra";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { countVisitCampaign } from "./services/welletService";

import "./App.css";
import "./css/animation.css";
import "./font.css";
import StripeConnectRefresh from "./screens/StripeConnectRefresh/StripeConnectRefresh";
import StripeConnectReturn from "./screens/StripeConnectReturn/StripeConnectReturn";
import errorRoutes from "./routes/error/errorRoutes";
import { getQueryString } from "./helpers/url";
import Home from "./screens/Home/Home";
import CitySelector from "./components/CitySelector/CitySelector";
import { DataManagerProvider } from "./context/DataManager";
import { getSetup } from "./actions/appActions";

const setTitle = (title) => {
  document.title = title;
};

const router = createBrowserRouter([
  errorRoutes,
  {
    path: "/",
    element: <Home />,
    children: [],
  },
  {
    path: "/pay",
    element: <PaymentReservation />,
    children: [],
  },
  {
    path: "/success",
    element: <PaymentConfirm />,
    children: [],
  },
  {
    path: "/success-details",
    element: <PaymentDetails />,
    children: [],
  },
  {
    path: "/city-selector",
    element: <CitySelector />,
    children: [],
  },

  {
    path: "/venue/:showUrl",
    element: <Venue title={setTitle} />,
    children: [],
  },
  {
    path: "/venue/:showUrl/restaurant-reservation",
    element: <Reservation />,
    children: [],
  },
  {
    path: "/venue/:showUrl/reservation/:reservationType",
    element: <ReservationVip />,
    children: [],
  },
  {
    path: "/venue/:showUrl/vip-reservation-extra",
    element: <ReservationVipExtra />,
    children: [],
  },
  {
    path: "/venue/:showUrl/reservation-check",
    element: <ReservationCheck />,
    children: [],
  },
  {
    path: "/venue/:showUrl/reservation-success",
    element: <ReservationSuccess />,
    children: [],
  },
  {
    path: "/onboarding-stripe/refresh/:token",
    element: <StripeConnectRefresh />,
    children: [],
  },
  {
    path: "/onboarding-stripe/return/:token",
    element: <StripeConnectReturn />,
    children: [],
  },
]);

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector((state) => state.app.language);
  const ctCookieTime = useSelector((state) => state.app.ctCookieTime);
  const webStatus = useSelector((state) => state.app.status);

  const refcodeCookie = getCookie("refCode");
  const smIdCookie = getCookie("smId");
  const refcode = getQueryString("refCode");
  const smId = getQueryString("smId");

  React.useEffect(() => {
    if (webStatus === "READY") {
      if (refcode) {
        setCookie("refCode", refcode, ctCookieTime);
        setCookie("smId", smId, ctCookieTime);
        dispatch({ type: "SET_REF_CODE", payload: refcode });
      } else if (refcodeCookie)
        dispatch({ type: "SET_REF_CODE", payload: refcodeCookie });

      if (smId) dispatch({ type: "SM_ID", payload: smId });
      else if (smIdCookie) dispatch({ type: "SM_ID", payload: smIdCookie });
    }
  }, [webStatus]);

  useEffect(() => {
    if (language && refcode && smId) {
      countVisitCampaign(refcode, language, smId);
    }
  }, [language, refcode]);

  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", payload: i18n.resolvedLanguage });
    dispatch(getSetup());
  }, []);

  return (
    <WelletContextProvider>
      <DataManagerProvider>
        <ContentApp>
          {webStatus === "READY" && language !== null ? (
            <RouterProvider
              router={router}
              fallbackElement={<LoadingScreen />}
            />
          ) : (
            <LoadingScreen />
          )}
        </ContentApp>
      </DataManagerProvider>
    </WelletContextProvider>
  );
}

export default App;
