import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ErrorIcon from "../../components/Icons/ErrorIcon";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import WelletContext from "../../context/WelletContext";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Footer from "../../components/Common/Footer/Footer";
import ReservationTypeCardIcon from "../../components/ReservationTypeCard/ReservationTypeCardIcon";

const Venue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { showUrl } = useParams();
  const [show, setShow] = useState(null);

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/get/${showUrl}`,
        {
          params: {
            lang: language,
          },
        }
      );

      setShow(result.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    getShow();
  }, []);

  const handleClick = (reservationType) => {
    navigate({
      pathname:
        reservationType === "restaurant"
          ? `/venue/${showUrl}/restaurant-reservation`
          : `/venue/${showUrl}/reservation/${reservationType}`,
    });
  };

  const renderSuccess = () => {
    return (
      <div className="page-container">
        <div className="page-content">
          <Header
            title={
              <div className="text-capitalize px-3">
                {t("book") + " " + show.name}
              </div>
            }
          />

          {show.reservationTypes.map((r) => (
            <div className="mt-3" key={r.id}>
              <ReservationTypeCardIcon
                title={t(`reservation${r.type.toLowerCase()}`)}
                type={r.type}
                onHandleClick={() => handleClick(r.type.toLowerCase())}
              />
            </div>
          ))}
        </div>
        <Footer show={show} />
      </div>
    );
  };

  const renderError = () => {
    return (
      <>
        <div className="success-content">
          <div className="text-center">
            <ErrorIcon color={"var(--color-primary)"} size="135px" />
          </div>
          <div className="mt-5 px-2">{t("callApiError")}</div>
        </div>
      </>
    );
  };

  return loading ? (
    <LoadingScreen />
  ) : error === null ? (
    renderSuccess()
  ) : (
    renderError()
  );
};

export default Venue;
