import './money.css';

const Money = ({
  value,
  currency = null,
  symbol = null,
  currencyCode = null,
  convert = true,
  decimal = true,
  lowDecimals = false,
  showSymbol = true,
}) => {


  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value === null || isNaN(value)) return;

  if (symbol === null) {
    symbol = "$";
  }

  const integerPart = parseInt(value.toFixed(2));
  const decimalPart = value.toFixed(2).slice(-2);

  return (
    <div className={`money ${!decimal ? 'mx-1 p-0' : 'mx-1'}`}>
      <div className='integer'>
        {currencyCode !== null ? currencyCode : null}{' '}
        {showSymbol ? (
          <span className='mr-1'>
            {symbol}
          </span>
        ) : (
          ''
        )}
        {integerPart.toLocaleString()}
        {lowDecimals ? `.${decimalPart}` : ''}
      </div>
      {decimal ? <span className='moneyCents'>{decimalPart}</span> : ''}
    </div>
  );
};

export default Money;
