//la info del currency se guarda dentro de app.currencies
export default (
  state = {
    all: null,
    selected: {
      isDefault: true,
      shortForm: "MXN",
      name: "Pesos Mexicanos",
      symbol: "$",
      exchangeRate: 1,
    },
    default: null,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_CURRENCIES":
      const defaultCurrency = action.payload.find((c) => c.isDefault);
      const selectedCurrency = defaultCurrency;
      return {
        ...state,
        all: action.payload,
        selected: selectedCurrency,
        default: defaultCurrency,
      };
    case "CHANGE_CURRENCY":
      return {
        ...state,
        selected: state.all.find(
          (c) => c.shortForm === action.payload.shortForm
        ),
      };
    default:
      return state;
  }
};
