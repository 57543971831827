import axios from "axios";

export const setLanguage = (language) => ({
  type: "SET_LANGUAGE",
  language: language,
});

export const fetchShow = async (
  lang,
  cityId,
  page,
  pageSize,
  welletContext,
  searchQuery,
  categoryId,
  subcategoryId,
  organizationId
) => {
  const response = await welletContext.apis.tickets.post("/shows/getAll", {
    lang: lang,
    cityId: cityId,
    page: page,
    pageSize: pageSize,
    nameFilter: searchQuery,
    categoryId: categoryId,
    subcategoryId: subcategoryId,
    organizationId,
  });

  return response.data;
};

export const getSetup = () => {
  return async (dispatch) => {
    try {
      const setup = await axios.get(
        process.env.REACT_APP_SALES_API_URL + "/web/setup"
      );
      dispatch({
        type: "GET_SETUP",
        payload: setup.data,
      });
    } catch (error) {
      console.error("Error fetching setup data:", error);
    }
  };
};
