import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { confirmStripeOnboarding } from "../../services/welletService";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Button from "../../components/Common/Button/Button";

const StripeConnectReturn = () => {
    const { token } = useParams();
    const [onboarding, setOnboarding] = useState(null);
    const navigate = useNavigate();

    const confirm = async () => {
        var decodedToken = decodeURIComponent(token);
        const onboardingResult = await confirmStripeOnboarding(decodedToken);
        setOnboarding(onboardingResult);
    }

    useEffect(() => {
        confirm();
    }, []);

    if(!onboarding) {
        return (
            <LoadingScreen />
        )
    }

    if(onboarding.isComplete) {
        return (
            <div>
                Se ha vinculado exitosamente tu cuenta de Stripe. Puedes continuar utilizando la aplicación.
            </div>
        )
    }

    return (
        <div>
            No has completado tu vinculación con Stripe. Por favor, haz click en el botón de abajo y completa los datos faltantes.
            <Button
                className="btn-primary mt-3 mx-auto w-100"
                size="medium"
                fontSize={"16px"}
                text="Completar vinculación con Stripe"
                onClick={() => navigate({
                    pathname: `/onboarding-stripe/refresh/${encodeURIComponent(token)}`
                    })}
                type="text"
                backgroundColor="var(--color-primary)"
                color="#000"
                iconColor="#000"
              />
        </div>
    )

}

export default StripeConnectReturn;