import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { getLocale } from "../../helpers/dates";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaxSelector from "./PaxSelector";
import CalendarReservationIcon from "../../components/Icons/CalendarReservationIcon";

const ReservationSelector = ({
  selectedPax,
  setSelectedPax,
  selectedDate,
  enableSelect,
  calendarVisible,
  setCalendarVisible,
  calendarRef,
  initialSlide,
}) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [showInput, setShowInput] = useState(false);
  const sliderRef = useRef();
  const minPax = 1;
  const maxPax = 100;

  useEffect(() => {
    if (initialSlide > 20) {
      setShowInput(true);
      setSelectedPax(initialSlide);
    }
  }, []);

  const handleChangePerson = (selectedOption) => {
    if (selectedOption && selectedOption === "+20") {
      setShowInput(true);
      setSelectedPax(21);
    } else if (initialSlide > 20) {
      setShowInput(true);
      setSelectedPax(selectedOption);
    } else {
      setShowInput(false);
      setSelectedPax(selectedOption);
    }
  };

  const handleCustomPax = (event) => {
    const inputValue = event.target.value;
    setSelectedPax(inputValue);
  };

  const handleIncrement = () => {
    if (selectedPax < maxPax) {
      setSelectedPax((prevValue) => prevValue + 1);
    }
  };

  const handleDecrement = () => {
    if (selectedPax > minPax) {
      setSelectedPax((prevValue) => prevValue - 1);
    }
  };

  const pax = [...Array(20).keys()].map((i) => i + 1);

  pax.push("+20");

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    if (calendarVisible) {
      const handleClickOutsideCalendar = (event) => {
        if (calendarVisible && calendarRef.current) {
          const calendarElement = calendarRef.current;
          const selectElement = document.querySelector(".product-selector");
          if (
            !calendarElement.contains(event.target) &&
            !selectElement.contains(event.target)
          ) {
            setCalendarVisible(false);
            enableSelect();
          }
        }
      };

      document.addEventListener("click", handleClickOutsideCalendar);

      return () => {
        document.removeEventListener("click", handleClickOutsideCalendar);
      };
    }
  }, [calendarVisible]);

  return (
    <>
      <div>
        <span className="text-secondary fw-bold">{t("numberGuests")}</span>
        <PaxSelector
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          selectedPax={selectedPax}
          handleCustomPax={handleCustomPax}
          showInput={showInput}
          sliderRef={sliderRef}
          pax={pax}
          handleChangePerson={handleChangePerson}
          initialSlide={initialSlide}
        />
      </div>

      <div className="mt-4">
        <span className="text-secondary fw-bold">{t("reservationDate")}</span>
        <div className="font-medium fw-bold" onClick={setCalendarVisible}>
          {capitalize(
            format(
              selectedDate,
              locale.code === "es" ? "eeee dd 'de' MMMM" : "eeee dd MMMM",
              {
                locale,
              }
            )
          )}
          <CalendarReservationIcon
            size={"1.4rem"}
            color={"var(--color-primary)"}
            styles={{ marginLeft: ".5rem", marginBottom: "-5px" }}
          />
        </div>
      </div>
    </>
  );
};

export default ReservationSelector;
