import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, isSubmitting]);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    setErrors(await validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event, key, value) => {
    if (event) {
      event.persist();
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));

      //Delete error on change
      setErrors((errors) => ({
        ...errors,
        [event.target.name]: null,
      }));

    } else if (key) {
      setValues((values) => ({
        ...values,
        [key]: value,
      }));

      //Delete error on change
      setErrors((errors) => ({
        ...errors,
        [key]: null,
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
};

export default useForm;