import axios from "axios";
const baseUrl = process.env.REACT_APP_SALES_API_URL;

export const previewReservation = async (showId, date, products, language) => {
  try {
    const response = await axios.post(`${baseUrl}/Reservation/Preview`, {
      showId: showId,
      reservationDateLocalTime: date,
      products: products,
      language: language,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReservation = async (token) => {
  try {
    const response = await axios.get(
      `${baseUrl}/reservation/GetByToken?token=${encodeURIComponent(token)}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPaidReservation = async (token) => {
  try {
    const response = await axios.get(
      `${baseUrl}/reservation/getpaid?token=${encodeURIComponent(token)}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const payReservation = async (
  token,
  card,
  customer,
  amount,
  installments,
  processingMode,
  deviceId
) => {
  try {
    const body = {
      token,
      card,
      customer,
      amount,
      installments,
      processingMode,
      deviceId,
    };

    const response = await axios.post(
      `${baseUrl}/reservation/payDeposit`,
      body
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkPayment = async (token, chargeId) => {
  try {
    const body = {
      token,
      chargeId,
    };

    const response = await axios.post(
      `${baseUrl}/reservation/checkPayment`,
      body
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProduct = async (productId, language) => {
  try {
    const response = await axios.get(
      `${baseUrl}/products/get/${productId}?lang=${language}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTerms = async (showId, lang, reservationType) => {
  try {
    const result = await axios.get(
      `${baseUrl}/shows/GetTermsAndConditions/${showId}`,
      { params: { lang: lang, reservationType: reservationType } }
    );

    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getShow = async (showUrl, lang) => {
  try {
    const result = await axios.get(
      `${baseUrl}/shows/Get/${showUrl}?lang=${lang}`
    );

    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSchedule = async (showId, date, reservationType) => {
  try {
    const response = await axios.get(
      `${baseUrl}/shows/GetSchedule?showId=${showId}&date=${date}&reservationType=${reservationType}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const countVisitCampaign = async (refCode, lang,smId) => {
  try {
    await axios.get(`${baseUrl}/campaign/view-event`, {
      params: {
        refCode,
        smId,
        lang,
        device: "mobile",
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStripeOnboardingRefreshLink = async (onboardingToken) => {
  try {
    const response = await axios.post(
      `${baseUrl}/withdrawal/stripe/RefreshOnboardingLink`,
      {
        onboardingToken,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmStripeOnboarding = async (onboardingToken) => {
  try {
    const response = await axios.post(
      `${baseUrl}/withdrawal/stripe/ConfirmOnboardingToken`,
      {
        onboardingToken,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};