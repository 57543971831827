import { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import InputForm from "../InputForm/InputForm.js";
import InputPhone from "../InputPhone/InputPhone.js";
import useForm from "../../helpers/useForm.js";
import { getToken } from "../../services/mercadoPagoService.js";
import ButtonProcessing from "../ButtonProcessing/ButtonProcessing.js";
import {
  getTerms,
  payReservation,
  checkPayment,
} from "../../services/welletService.js";
import Money from "../Money/Money.js";
import ErrorBox from "../ErrorBox/ErrorBox.js";
import Checkbox from "../Checkbox/Checkbox.js";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../../css/selector.css";

import Modal from "../Modal/Modal.js";
import Header from "../Header/Header.js";
import { t } from "i18next";

const StripeCheckoutForm = ({ token, reservation, showId }) => {
  const [btnStatus, setBtnStatus] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [terms, setTerms] = useState(null);
  const navigate = useNavigate();

  const [stripeToken, setStripeToken] = useState(null);
  const [stripeErrorMessage, setStripeErrorMessage] = useState('');
  const [stripeReady, setStripeReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const validate = async (values) => {
    setBtnStatus("INPROGRESS");
    setStripeErrorMessage('');
    let errors = {};

    if (!values.ccname) {
      errors.ccname = t("error.name");
    }

    if (
      !values.email ||
      !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(values.email)
    ) {
      errors.email = t("error.email");
    }

    if (!values.customerName) {
      errors.customerName = t("error.name");
    }

    if (!acceptedTerms) errors.acceptedTerms = t("form.terms3");

    try {
      setStripeToken(null);
      const {error: submitError} = await elements.submit();
      if(submitError) {
        errors.stripeError = submitError;
      }
      else {
          const { error, confirmationToken } = await stripe.createConfirmationToken({elements});
          if (error) {
            errors.stripeError = error.message;
            setStripeErrorMessage(error.message);
          } else {
            setStripeToken(confirmationToken);
          }
      }
    } catch (error) {
      console.error('Error creating confirmationToken:', error);
    }

    if (Object.keys(errors).length > 0) {
      setBtnStatus("NORMAL");
    }

    return errors;
  };

  const sendForm = async () => {
    setError("");
    setStripeErrorMessage('');

    const customer = {
      name: values.customerName,
      email: values.email,
      phone: values.phone,
    };

    try {
      const stripeCard = stripeToken.payment_method_preview?.card;
      const card = {
        token: stripeToken.id,
        brand: stripeCard?.brand,
        number: '',
        cvc: '',
        expiryMonth: stripeCard?.exp_month || '',
        expiryYear: stripeCard?.exp_year || '',
        bin: '',
        country: stripeCard?.country,
        last4: stripeCard?.last4
      };

      const pay = await payReservation(
        token,
        card,
        customer,
        reservation.remainingDepositAmount,
        1
      );
      if (pay.success) {
        setBtnStatus("FINISHED");
        setTimeout(() => {
          navigate({
            pathname: "/success",
            search: createSearchParams({ token: token }).toString(),
          });
        }, 1000);
      } else if (pay.status === "PENDING_CHALLENGE") {
        handleNextActions(pay.clientSecret, token, pay.chargeId);
      } else {
        setBtnStatus("NORMAL");
        setError(pay.error);
      }
    } catch (e) {
      setBtnStatus("NORMAL");
      if (e.response && e.response.data && e.response.data.errorCode)
        setError(e.response.data.errorCode);
      else if (e.code === "ERR_NETWORK" || e.code === "ECONNABORTED") {
        setError("ERR_INTERNET_DISCONNECTED");
      } else {
        setError("API_ERROR_UNKNOWN");
      }
    }
  };

  const handleNextActions = async (clientSecret, orderToken, chargeId) => {
    const {
      error,
      paymentIntent
    } = await stripe.handleNextAction({
      clientSecret: clientSecret
    });

    if(error && error.message) {
      setBtnStatus("NORMAL");
      setStripeErrorMessage(error.message);

      // send payment to server, so we save the payment detail with the error
      await checkPayment(
          orderToken,
          chargeId
        );
      return;
    }

    const pay =  await checkPayment(
        orderToken,
        chargeId
      );

    if (pay.success && pay.status === 'SUCCESS') {
        setBtnStatus('FINISHED');
        navigate({
          pathname: "/success",
          search: createSearchParams({ token: token }).toString(),
        });
    }
    else {
      setBtnStatus("NORMAL");
      if (pay.error) {
          setError(pay.error);
      } else {
          setError('GENERIC_ERROR');
      }
    }

  }

  
  const { values, errors, handleChange, handleSubmit } = useForm(
    sendForm,
    validate
  );

  const handleCheck = (event) => {
    if (event.target.checked) {
      setAcceptedTerms(true);
      errors.acceptedTerms = null;
    } else {
      setAcceptedTerms(false);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, []);

  const setDefaultValues = () => {
    values.customerName = reservation.customerName;
    values.email = reservation.customerEmail;
    values.phone = reservation.customerPhone;
  };

  const treatments = [
    {
      value: "sr",
      label: "Sr.",
    },
    {
      value: "sra",
      label: "Sra.",
    },
    {
      value: "srta",
      label: "Srita.",
    },
  ];

  const handleTerms = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const getTermsAndConditions = async (showId, lang) => {
    try {
      const result = await getTerms(showId, lang);
      setTerms(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTermsAndConditions(showId, reservation.language);
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderErrorBox = () => {
    if (stripeErrorMessage) {
      return (
        <div className='mt-4'>
          <ErrorBox title={t('paymentErrors.title')} description={stripeErrorMessage} />
        </div>
      );
    }

    if (error !== '') {
      return (
        <div className='mt-4'>
          <ErrorBox title={t('paymentErrors.title')} description={t('paymentErrors.' + error)} />
        </div>
      );
    }

    return null;
  };

  const renderPurchaseButton = () => {
    if(!stripeReady) return null;

    return (
      <div className="mt-4">
          <div>
            <ButtonProcessing
              status={btnStatus}
              text={t("form.payButton")}
              amount={<Money value={reservation.remainingDepositAmount} />}
              inProgressText={t("form.processing")}
              size="large"
              type="submit"
              onClick={handleSubmit}
              arrow
              color="#000"
              backgroundColor="var(--color-primary)"
              iconColor="#000"
            />
          </div>
        </div>
    );
  }

  const stripeOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
    },
  };

  return (
    <>
      <form id="form-checkout" onSubmit={handleSubmit}>
        <div className="mt-4">
          <div className="mt-4">
            <InputForm
              name={"ccname"}
              type={"text"}
              value={values.ccname}
              error={errors.ccname}
              label={t("form.cardHolderName")}
              placeholder={t("form.cardHolder")}
              onChange={handleChange}
              dataCheckout={"fullName"}
            />
          </div>

          <div className="mt-4">
            <InputForm
              name={"email"}
              type={"email"}
              value={values.email}
              error={errors.email}
              icon="mail"
              label={"E-mail"}
              placeholder={t("form.reservationEmail")}
              onChange={handleChange}
            />
          </div>
          
          <div className="mt-4">
            <PaymentElement
              options={stripeOptions}
              onReady={() => setStripeReady(true)}
            />
          </div>
          
          <div
            className="font-small text-center position-relative mt-4"
            style={{ paddingBottom: errors.acceptedTerms ? "27px" : "0" }}
          >
            <Checkbox
              label={
                <div>
                  {t("form.terms1")}{" "}
                  <u onClick={handleTerms}>{t("form.terms2")}</u>
                </div>
              }
              onChange={handleCheck}
            />
            {errors.acceptedTerms ? (
              <div className="errorLabel mt-1">{t("form.terms3")}</div>
            ) : null}
          </div>
        </div>
        {renderErrorBox()}
        {renderPurchaseButton()}
      </form>
      <Modal
        open={showModal}
        fullScreen
        showCloseButton={false}
        animationClass="slide-left-right"
        className={"terms-and-conditions"}
      >
        <Header onClick={handleModalClose} title={t("form.terms2")} />
        <div
          dangerouslySetInnerHTML={{ __html: terms }}
          className="container"
        ></div>
      </Modal>
    </>
  );
};

export default StripeCheckoutForm;
