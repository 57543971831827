import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WelletContext from "../../../context/WelletContext";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import { addMinutes, format } from "date-fns";
import {
  convertTime12to24,
  getDateFromOffset,
  getDatePerformance,
  getLocale,
} from "../../../helpers/dates";
import { useTranslation } from "react-i18next";
import useForm from "../../../helpers/useForm";
import OccasionSelector from "../../../components/ReservationCheck/OccasionSelector/OccasionSelector";
import ReservationCheckCustomerInfo from "../../../components/ReservationCheck/ReservationCheckCustomerInfo/ReservationCheckCustomerInfo";
import CustomerSpecialRequest from "../../../components/ReservationCheck/CustomerSpecialRequest/CustomerSpecialRequest";
import ErrorBox from "../../../components/Common/ErrorBox";
import styles from "./reservationCheck.module.css";
import Summary from "../../../components/ReservationCheck/Summary/Summary";
import SummaryProducts from "../../../components/ReservationCheck/Summary/SummaryProducts";
import SummaryBox from "../../../components/ReservationCheck/Summary/SummaryBox";
import Money from "../../../components/Money/Money";
import "../../../components/ReservationDetail/reservation-details.css";
import ButtonProcessing from "../../../components/ButtonProcessing/ButtonProcessing";
import ProductPerformanceSelector from "../../../components/ProductPerformanceSelector/ProductPerformanceSelector";
import Header from "../../../components/Header/Header";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";

const ReservationCheck = () => {
  const ButtonStatus = {
    Normal: "NORMAL",
    InProgress: "INPROGRESS",
    ProgressFinished: "FINISHED",
    Error: "ERROR",
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const showId = useSelector((state) => state.reservation.showId);
  const restaurantName = useState(
    useSelector((state) => state.reservation.showName)
  );
  const [paxs, setPaxs] = useState(
    useSelector((state) => state.reservation.paxs)
  );
  const reservation = useSelector((state) => state.reservation);
  const hasProducts = reservation.products;
  const date = reservation.date;
  const performance = reservation.performance;
  const products = reservation.products;
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [selectedButton, setSelectedButton] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const [reservationInfo, setReservationInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reservationDate, setReservationDate] = useState(null);
  const refCode = useSelector((state) => state.associates.refCode);
  const smId = reservation.smId;
  const { showUrl } = useParams();
  const [intervals, setIntervals] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [minDateTime, setMinDateTime] = useState(null);

  const handleSelectOccasion = (value) => {
    if (selectedButton === value) {
      setSelectedButton(null);
      handleChange(undefined, "occasion", "");
    } else {
      handleChange(undefined, "occasion", value);
      setSelectedButton(value);
    }
  };

  function validate(values) {
    let errors = {};

    if (!values.customerName) {
      errors.customerName = t("nameError.EMPTY_NAME");
    } else if (/\d/.test(values.customerName)) {
      errors.customerName = t("nameError.NOT_NUMBERS");
    } else if (values.customerName.length < 3) {
      errors.customerName = t("nameError.MORE_LETTERS");
    }

    if (!values.phone) {
      errors.phone = t("phoneError.EMPTY_PHONE_NUMBER");
      setPhoneError(errors.phone);
    } else {
      setPhoneError("");
    }

    if (values.phone && !isValidPhoneNumber(values.phone)) {
      errors.phone = t("phoneError.INVALID_PHONE_NUMBER");
      setPhoneError(errors.phone);
    }

    if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t("emailError.INVALID_EMAIL");
    }

    if (!performance && !selectedPerformance) {
      errors.performance = "Seleccione el horario de llegada";
    }

    return errors;
  }

  const sendForm = async () => {
    setBtnStatus(ButtonStatus.InProgress);
    let reservationLocalTime = "";

    if (selectedPerformance != null)
      reservationLocalTime = date + "T" + selectedPerformance;
    else reservationLocalTime = getDatePerformance(date, performance);

    try {
      let url = "/reservation/generatereservation";
      if (reservationInfo && reservationInfo.parentReservationReferenceCode) {
        url = "/reservation/modifyreservation";
      }

      const result = await welletContext.apis.tickets.put(url, {
        showId: showId,
        paxs: paxs,
        customerName: values.customerName,
        customerPhone: values.phone,
        customerLanguage: language,
        email: values.email ?? "",
        comments: values.comments ?? "",
        occasion: values.occasion ?? null,
        countryCode: values.countryCode ?? "MX",
        reservationDateLocalTime: reservationLocalTime,
        totalAmount: reservationInfo?.totalAmount ?? null,
        products: products ?? null,
        refCode: refCode,
        smId: smId,
      });

      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        navigate(`/venue/${showUrl}/reservation-success`);
      }, 1500);
    } catch (error) {
      setBtnStatus(ButtonStatus.Error);
      let errorMessage;
      switch (error.response.data.errorType) {
        case "GENERIC_ERROR":
          errorMessage = t("reservationError.GENERIC_ERROR");
          break;
        case "SHOW_NOT_FOUND":
          errorMessage = t("reservationError.SHOW_NOT_FOUND");
          break;
        case "SCHEDULE_SOLD_OUT":
          errorMessage = t("reservationError.SCHEDULE_SOLD_OUT");
          break;
        case "INVALID_RESERVATION_DATE":
          errorMessage = t("reservationError.INVALID_RESERVATION_DATE");
          break;
        default:
          errorMessage = t("reservationError.GENERIC_ERROR");
      }
      setError(errorMessage);
    }
  };

  let { values, errors, handleChange, handleSubmit } = useForm(
    sendForm,
    validate
  );

  const getReservation = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.post(
        "/Reservation/Preview",
        {
          showId: showId,
          reservationDateLocalTime: date,
          products: products,
          language: language,
        }
      );
      if (response.status === 200) {
        setReservationInfo(response.data);
        setInitialValues(response.data);
        setPaxs(response.data.paxs);

        const todayDate = new Date(
          getDateFromOffset(response.data.show.utcOffset)
        );
        setReservationDate(new Date(date + "T00:00:00"));
        setMinDateTime(addMinutes(todayDate, 60));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSchedule = async () => {
    setLoadingSchedule(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/GetSchedule?showId=${showId}&date=${date}&reservationType=${reservation.reservationType}`
      );

      if (result.data.length === 0) {
        setIntervals([]);
      }

      const formattedTimes = result.data?.map((time) => {
        const time24 = new Date(date + "T" + convertTime12to24(time.timeStart));

        if (time.timeMinutesStart >= 0 && time.timeMinutesStart <= 360)
          time24.setDate(time24.getDate() + 1);

        return { time24, soldOut: time.isSoldOut };
      });
      formattedTimes.sort();

      const allIntervals = formattedTimes.map(({ time24, soldOut }) => ({
        time: time24,
        soldOut,
      }));

      setIntervals(allIntervals);
      setLoadingSchedule(false);
    } catch (error) {
      console.error(error);
    }
  };

  const setInitialValues = (reservation) => {
    handleChange(null, "customerName", reservation.customerName);
    handleChange(null, "phone", reservation.customerPhone);
  };

  useEffect(() => {
    handleChange(null, "language", language);

    if (date && performance) {
      setReservationDate(new Date(date + "T" + performance));
    }

    if (hasProducts) {
      getReservation();
      getSchedule();
    }
  }, []);

  return (hasProducts && loading) || reservationDate === null ? (
    <LoadingScreen />
  ) : (
    <>
      <Header
        title={
          <div className="text-capitalize">
            {t("book")} {restaurantName}
          </div>
        }
      />
      <div className={`order-details ${styles.reservation}`}>
        <div className="mt-3 fw-bold">
          <div className="show-name">{restaurantName}</div>
          <div className="bold text-lighter-gray">
            <div className="mt-1">
              {reservationInfo?.kidsPaxs > 0 ? (
                <>
                  {t("adult", {
                    count: reservationInfo?.paxs - reservationInfo?.kidsPaxs,
                  })}{" "}
                  + {t("kid", { count: reservationInfo?.kidsPaxs })}
                </>
              ) : (
                t("person", { count: paxs })
              )}
            </div>
            <div className="mt-1">
              {locale.code === "es"
                ? format(reservationDate, "EEEE, d 'de' MMMM", { locale })
                : format(reservationDate, "EEEE, d MMMM", { locale })}
            </div>
            {performance ? <div className="mt-1">{performance} hr</div> : null}
          </div>
        </div>
        {reservationInfo ? (
          <>
            <div className={styles.paymentSection}>
              <SummaryProducts reservationInfo={reservationInfo} />
              {reservationInfo.consumptionAmount > 0 && (
                <div className={`${styles.consumption} mt-4`}>
                  {t("consumptionIncluded", {
                    consumption: "$" + reservationInfo?.consumptionAmount,
                  })}
                </div>
              )}
            </div>
            <div className={styles.paymentSection}>
              <Summary reservation={reservationInfo} />
            </div>
            {reservationInfo.remainingAmount > 0 ? (
              <div className={`row justify-content-center my-4`}>
                <div className="col-4 pr-1">
                  <SummaryBox
                    title="Total"
                    value={reservationInfo.totalAmount}
                  />
                </div>
                <div className="col-4 px-2">
                  <SummaryBox
                    title={t("deposit")}
                    value={reservationInfo.depositAmount}
                    subtitle={t("productsInfo.payUponConfirmation")}
                  />
                </div>
                <div className="col-4 pl-1">
                  <SummaryBox
                    title="Balance"
                    value={reservationInfo.remainingAmount}
                    subtitle={t("productsInfo.payUponArrival")}
                  />
                </div>
              </div>
            ) : null}
            {reservationInfo.paymentDetails &&
            reservationInfo.paymentDetails.length > 0 ? (
              <div className={styles.paymentSection}>
                <div className="bold">{t("paymentsMade")}</div>
                {reservationInfo.paymentDetails.map((payment) => (
                  <div className="row mt-3" key={payment.chargeId}>
                    <div className="col">
                      <div className="fw-bold font-small">
                        {t("payment")} #{payment.chargeId}
                      </div>
                      <div className="text-secondary font-tiny mt-1">
                        <div>
                          <span className="text-capitalize">
                            {payment.brand}
                          </span>
                          (xxxx xxxx xxxx {payment.last4})
                        </div>
                        <div>
                          {format(
                            new Date(payment.paymentLocalDate),
                            "PPP HH:mm",
                            {
                              locale,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`col-auto ${styles.paymentAmount}`}>
                      -<Money value={payment.amount} lowDecimals={true} />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {reservationInfo?.remainingDepositAmount > 0 &&
            reservationInfo.parentReservationReferenceCode ? (
              <div className={styles.paymentSection}>
                <div className="row font-normal">
                  <div className="col fw-bold pr-0">
                    {t("remainingBalance")}:
                  </div>
                  <div className="col-auto fw-bold">
                    <Money
                      value={reservationInfo?.remainingDepositAmount}
                      symbolSize="14px"
                      decimal={false}
                      lowDecimals={true}
                    />
                  </div>
                </div>
                <div className="font-small text-secondary mt-4 px-3 text-center">
                  {t("remainingBalanceText")}
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {reservationInfo?.customerName ? (
          <div className={styles.paymentSection}>
            <div className="text-secondary text-medium fw-semibold">
              {t("bookingHolder")}
            </div>
            <div className="font-normal fw-bold mt-1">
              {reservationInfo.customerName}
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <ReservationCheckCustomerInfo
              handleChange={handleChange}
              values={values}
              errors={errors}
              phoneError={phoneError}
              setPhoneError={setPhoneError}
            />
          </div>
        )}

        {!performance ? (
          <div className="position-relative">
            <div className="mt-5" style={{ marginBottom: "-1rem" }}>
              <p className="m-0 fw-bold">{t("arrivalTime")}</p>
              <div className="mt-3">
                <ProductPerformanceSelector
                  performances={intervals}
                  onSelect={setSelectedPerformance}
                  selectedPerformance={selectedPerformance}
                  language={language}
                  selectedDate={date}
                  loadingSchedule={loadingSchedule}
                  today={minDateTime}
                />
              </div>
            </div>
            {errors.performance && errors.performance.length > 0 && (
              <div
                className="errorLabel mt-2 position-relative"
                style={{ color: "#fff" }}
              >
                <span></span> {errors.performance}
              </div>
            )}
          </div>
        ) : null}

        <div className="mt-5">
          <OccasionSelector
            handleSelectButton={handleSelectOccasion}
            selectedButton={selectedButton}
          />
        </div>

        <div className="mt-5">
          <CustomerSpecialRequest
            open={open}
            setOpen={setOpen}
            values={values}
            handleChange={handleChange}
          />

          {error && (
            <>
              <ErrorBox title={error} />
              <div
                className="mt-4 text-center text-primary"
                onClick={() => navigate(-1)}
              >
                {t("reschedule")}
              </div>
            </>
          )}
        </div>
        {/* {hasProducts && reservationInfo.remainingDepositAmount > 0 && (
          <div className="mt-4">
            <div className="card text-center font-small p-3">
              <div className="fw-bold font-tiny text-uppercase d-flex justify-content-center align-items-center">
                {t("important")}{" "}
                <WarningCircleIcon
                  color={"var(--color-warning)"}
                  size={"20px"}
                  styles={{ marginLeft: ".5rem" }}
                />
              </div>
              <div className="fw-bold mt-1">{t("paymentRequiredTitle")}</div>
              <div className="mt-3">
                <div>{t("paymentRequiredText")}</div>
                <div className="font-1rem fw-bold mt-2">
                  <Money
                    value={reservationInfo.remainingDepositAmount}
                    symbol={currency.shortForm}
                    lowDecimals={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )} */}
        <div className="env-bottom">
        <ButtonProcessing
          status={btnStatus}
          text={t("requestReservation")}
          size="large"
          className={`mt-4 ${styles.btn}`}
          type="submit"
          onClick={handleSubmit}
          arrow
          color="#000"
          backgroundColor="var(--color-primary)"
          iconColor="#000"
        />
        </div>
       
      </div>
    </>
  );
};

export default ReservationCheck;
