export const getQueryString = function (field, url) {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? decodeURIComponent(string[1]) : null;
  };

export const hasHash = function (field, url) {
  var href = url ? url : window.location.href;
  var reg = new RegExp('[#]' + field, 'i');
  var string = reg.exec(href);
  return string ? string[0].replace('#', '') === field : false;
};
  