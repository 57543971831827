import {
  format,
  addMonths,
  addDays,
  nextSunday,
  startOfWeek,
  isMonday,
  differenceInDays,
  isYesterday,
  isTomorrow,
  formatDistanceStrict,
  parse,
  isToday,
  parseISO,
} from 'date-fns';
import { enUS, es, pt, ru, de, zhCN, fr, hi } from 'date-fns/locale';

export const getLocale = (language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'de':
      return de;
    case 'cn':
      return zhCN;
    case 'fr':
      return fr;
    case 'hi':
      return hi;
    default:
      return enUS;
  }
};

export const getMonths = (language) => {
  const locale = getLocale(language);

  const months = [];
  let date = alignTimezone(new Date('2021-01-01'));

  for (let i = 0; i < 12; i++) {
    months.push(format(date, 'MMMM', { locale }));
    date = addMonths(date, 1);
  }
  return months;
};

export const getWeekDays = (language) => {
  const locale = getLocale(language);

  const days = [];
  let date = new Date('2021-01-01');
  date = nextSunday(date);

  for (let i = 0; i < 7; i++) {
    days.push(format(date, 'EEEE', { locale }));
    date = addDays(date, 1);
  }

  return days;
};

export const getShortWeekDays = (language) => {
  const locale = getLocale(language);

  const days = [];
  let date = new Date('2021-01-01');
  date = nextSunday(date);

  for (let i = 0; i < 7; i++) {
    days.push(format(date, 'EEE', { locale }));
    date = addDays(date, 1);
  }

  return days;
};

export const getWeekStartsOn = (language) => {
  const locale = getLocale(language);

  let date = new Date('2021-01-01');
  date = startOfWeek(date, { locale });

  if (isMonday(date)) {
    return 1;
  }
  return 0;
};

export const alignTimezone = (date, reverse = false) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let offset = date.getTimezoneOffset() * 60 * 1000;
  if (reverse) {
    offset *= -1;
  }
  return new Date(date.valueOf() + offset);
};

export const validateDate = (item, language, timeFormat = 'HH:mm:ss', dateFormat = 'MMM dd') => {
  const date = new Date(item);
  const now = new Date();
  const differenceInDaysNumber = differenceInDays(now, date);

  if (isYesterday(date)) {
    return 'Ayer';
  } else if (differenceInDaysNumber === 0) {
    return format(new Date(item), timeFormat, { locale: getLocale(language) });
  } else {
    return format(date, dateFormat, {
      locale: getLocale(language),
    });
  }
};

export const dateinText = (item, language, timeFormat = 'HH:mm') => {
  const date = new Date(item);
  const now = new Date();
  const differenceInDaysNumber = differenceInDays(now, date);

  let firsText = '';

  if (isYesterday(date)) {
    firsText = 'Ayer';
  } else if (isTomorrow(date)) {
    firsText = 'Mañana';
  } else if (differenceInDaysNumber === 0) {
    firsText = 'Hoy';
  } else {
    firsText = format(date, 'MMM dd', {
      locale: getLocale(language),
    });
  }

  return firsText + ' a las ' + format(new Date(item), timeFormat, { locale: getLocale(language) });
};

export const getFormatedDate = (date) => {
  if (date.endsWith('Z') || date.slice(-6) === '+00:00') return date;
  return date + '+00:00';
};

export const getExpirationTime = (expirationDate, language) => {
  let formatedDate = getFormatedDate(expirationDate);

  const expiration = new Date(formatedDate);
  const today = new Date();
  const difference = expiration - today;

  if (difference >= 3600000 && difference <= 86400000) {
    const remainingMinutes = Math.floor(difference / 60000) % 60;
    const hoursText = formatDistanceStrict(expiration, today, {
      unit: 'hour',
      locale: getLocale(language),
      roundingMethod: 'floor',
    });
    return remainingMinutes !== 0 ? `${hoursText} ${remainingMinutes} min` : hoursText;
  }

  return formatDistanceStrict(expiration, today, {
    locale: getLocale(language),
    roundingMethod: 'floor',
  });
};

export const getDateFromOffset = (offset = 0) => {
  let date = new Date();
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  date = date.setMinutes(date.getMinutes() + offset);

  return date;
};

export const toStringDate = (day) => {
  return format(day, 'yyyy-MM-dd');
};

export const generateDisabledDates = (startDate, endDate, locale) => {
  const disabledDates = [];
  const currentDate = new Date(startDate);
  while (currentDate < endDate) {
    const formattedDate = format(currentDate, 'yyyy-MM-dd', { locale });
    disabledDates.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return disabledDates;
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toUpperCase() === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  var stringHours = hours.length === 1 ? +'0' + hours : hours;

  return stringHours + ':' + minutes + ':00';
};

export const getMonthYear = (month, year, locale) => {
  const date = new Date(year, month - 1, 1);
  return format(date, 'MMMM yyyy', { locale: locale });
};

export const getDatePerformance = (date, performance) => {
  const dateObj = date && parse(date, 'yyyy-MM-dd', new Date());
  const performanceObj = performance && parse(performance, 'HH:mm', new Date());
  const shouldAddDay =
    performanceObj && performanceObj.getHours() >= 0 && performanceObj.getHours() <= 3;
  const nextDayDateObj = dateObj && (shouldAddDay ? addDays(dateObj, 1) : dateObj);
  const datePerformance =
    nextDayDateObj &&
    new Date(
      nextDayDateObj.getFullYear(),
      nextDayDateObj.getMonth(),
      nextDayDateObj.getDate(),
      performanceObj.getHours(),
      performanceObj.getMinutes(),
      0,
    );
  const datePerformancesFormatted = date && format(datePerformance, "yyyy-MM-dd'T'HH:mm:ss.SSS");
  return datePerformancesFormatted;
};

export const isTodayFormat = (date, locale, t) => {
  if (isToday(new Date(date))) {
    return t('today');
  } else {
    return format(parseISO(date), 'dd MMM', { locale });
  }
};
const capitalizeExceptDe = (text) => {
  const words = text.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.toLowerCase() !== 'de') {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.toLowerCase(); // Mantener "de" en minúsculas
  });
  return capitalizedWords.join(' ');
};

// 23 Abr, 2024

export const formatDateShort = (date, locale) => {
  const dateFromatted = format(
    new Date(date),
    locale.code === 'es' ? 'dd MMM, yyyy' : 'MMM dd, yyyy',
    {
      locale,
    },
  );
  return capitalizeExceptDe(dateFromatted);
};

//23 de Abril de 2024

export const formatDateLong = (date, locale) => {
  const dateFromatted = format(
    new Date(date),
    locale.code === 'es' ? `dd 'de' MMMM 'de' yyyy` : `MMMM dd, yyyy`,
    {
      locale,
    },
  );
  return capitalizeExceptDe(dateFromatted);
};

//Martes, 23 de Abr de 2024

export const formatDateWeekday = (date, locale) => {
  const dateFromatted = format(
    new Date(date),
    locale.code === 'es' ? `EEEE, dd 'de' MMM 'de' yyyy` : `EEEE, MMM dd, yyyy`,
    {
      locale,
    },
  );
  return capitalizeExceptDe(dateFromatted);
};

//23 de Abril de 2024 - 13:53

export const formatDateHours = (date, locale) => {
  const dateFromatted = format(
    new Date(date),
    locale.code === 'es' ? `dd 'de' MMMM 'de' yyyy - HH:mm` : `MMMM dd, yyyy - HH:mm`,
    {
      locale,
    },
  );
  return capitalizeExceptDe(dateFromatted);
};

//Abril 23, 2024

export const formatDateDefault = (date, locale) => {
  const dateFromatted = format(new Date(date), 'MMMM dd, yyyy', {
    locale,
  });
  return capitalizeExceptDe(dateFromatted);
};

//Martes, 23 de Abril
export const formatDateNormal = (date, locale) => {
  const dateFormat = format(
    new Date(date),
    locale.code === 'es' ? "EEEE, dd 'de' MMMM" : 'EEEE, dd MMMM',
    { locale },
  );
  return capitalizeExceptDe(dateFormat);
};

export const calendarDate = (date, locale) => {
  const dateFormat = format(new Date(date), 'MMM dd, yyyy', { locale });
  return capitalizeExceptDe(dateFormat);
};

export const reservationDetailDate = (date, locale) => {
  const formattedDate = format(
    new Date(date),
    locale.code === 'es' ? "EEEE, d 'de' MMMM 'a las' HH:mm 'hr'" : "EEEE, d MMMM 'at' HH:mm 'hr'",
    {
      locale,
    },
  );
  return capitalizeExceptDe(formattedDate);
};
