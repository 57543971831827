import React from "react";

const ImageModal = ({
  open = false,
  onClose,
  animationClass,
  fullScreen = false,
  children,
  showCloseButton = true,
  idModal,
  hasBackground = false,
  backIcon = false,
}) => {
  let modalClass = open ? "modal show d-block" : "modal";

  const isModalFull = fullScreen
    ? {}
    : { position: "absolute", top: 0, right: 0 };

  const handleModalClose = () => {
    onClose();
  };

  const modalContentStyle = fullScreen
    ? {}
    : { maxHeight: "50%", overflowY: "auto", backgroundColor: "transparent" };

  return (
    <div
      className={`${modalClass} ${animationClass}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      id={idModal}
      style={{ backgroundColor: "transparent" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ backgroundColor: "rgba(0, 0,0 ,0.9)" }}
      >
        <div
          className={`modal-content py-5 px-4 ${
            hasBackground && "background-modal"
          }`}
          style={modalContentStyle}
        >
          {showCloseButton && !backIcon && (
            <div style={isModalFull}>
              <button
                type="button"
                onClick={handleModalClose}
                className="close font-large text-secondary"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ color: "#fff" }}>
                  &times;
                </span>
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
