import { Skeleton } from '@mui/material';

export const skeletonPerformances = () => {
  const skeletons = [];

  for (let i = 0; i < 3; i++) {
    skeletons.push(
      <Skeleton
        key={i}
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '85%',
          borderRadius: '.4rem',
          mb: 1,
          height: 40,
        }}
      />,
    );
  }

  return skeletons;
};

export const skeletonHeaderPicture = () => {
  return (
    <div>
      <Skeleton sx={{ bgcolor: 'grey.900' }} variant='rectangular' width={'100%'} height={180} />
      <div className='activity-description p-3'>
        <div className='row'>
          <div className='col-auto pr-2 my-auto'>
            <Skeleton sx={{ bgcolor: 'grey.900' }} variant='circular' width={34} height={34} />
          </div>
          <div className='col my-auto px-0 '>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '7px' }}
              variant='rectangular'
              width={'50%'}
              height={19}
            />
            <div className='font-tiny text-secondary mt-2'>
              <Skeleton
                sx={{ bgcolor: 'grey.900', borderRadius: '7px' }}
                variant='rectangular'
                width={'90%'}
                height={19}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mx-3' style={{ borderBottom: '1px solid #606060' }}></div>
    </div>
  );
};

export const skeletonShow = () => {
  return (
    <div className='row align-items-center p-2'>
      <div className='col-2'>
        <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={40} height={40} />
      </div>
      <div className='col-10 pl-1'>
        <div>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem' }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem' }}
          />
        </div>
      </div>
    </div>
  );
};

export const skeletonHeaderTitle = () => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <Skeleton
        sx={{
          bgcolor: 'grey.900',
          borderRadius: '5px',
        }}
        variant='rectangular'
        width={'50%'}
        height={25}
      />
    </div>
  );
};

export const skeletonEmptyPerformances = () => {
  return (
    <div className='mt-3'>
      <Skeleton
        sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
        variant='rectangular'
        width={'100%'}
        height={150}
      />
    </div>
  );
};

export const skeletonSchedule = () => {
  return (
    <>
      <div className='mt-4 mb-1 fw-bold font-small'>Elige un horario a continuación:</div>
      <div className='performance-selector row mt-3 justify-content-center'>
        <div className='col-3 p-1 mt-2'>{skeletonPerformances()}</div>
        <div className='col-3 p-1 mt-2'>{skeletonPerformances()}</div>
        <div className='col-3 p-1 mt-2'>{skeletonPerformances()}</div>
        <div className='col-3 p-1 mt-2'>{skeletonPerformances()}</div>
      </div>
    </>
  );
};

export const skeletonOccassion = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        sx={{
          bgcolor: 'grey.900',
          borderRadius: '7px',
        }}
        variant='rectangular'
        width={'30%'}
        height={25}
      />
    </div>
  );
};
