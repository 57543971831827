import { useEffect, useState } from "react";
import InputForm from "../InputForm/InputForm";
import InputPhone from "../InputPhone/InputPhone";
import useForm from "../../helpers/useForm";
import { getToken } from "../../services/mercadoPagoService";
import ButtonProcessing from "../ButtonProcessing/ButtonProcessing";
import {
  getTerms,
  payReservation,
  checkPayment,
} from "../../services/welletService";
import Money from "../Money/Money";
import ErrorBox from "../ErrorBox/ErrorBox.js";
import Checkbox from "../Checkbox/Checkbox.js";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../../css/selector.css";
import MercadoPagoThreeDsModal from "../MercadoPagoThreeDsModal.js";

import styles from "./MercadoPago.module.css";
import Modal from "../Modal/Modal.js";
import Header from "../Header/Header.js";
import { t } from "i18next";

const MercadoPagoForm = ({ token, reservation, showId }) => {
  const [cardNumberElement, setCardNumberElement] = useState(null);
  const [securityCodeElement, setSecurityCodeElement] = useState(null);
  const [expirationDateElement, setExpirationDateElement] = useState(null);

  const [btnStatus, setBtnStatus] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [mpToken, setMpToken] = useState(null);
  const [error, setError] = useState("");
  const [errorCardNumber, setErrorCardNumber] = useState(null);
  const [errorCardExpDate, setErrorCardDate] = useState(null);
  const [errorCardCvc, setErrorCardCvc] = useState(null);

  const [threeDsChallenge, setThreeDsChallenge] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [terms, setTerms] = useState(null);
  const navigate = useNavigate();

  const payment = { key: reservation?.gateway?.publicKey };

  const validate = async (values) => {
    setBtnStatus("INPROGRESS");
    let errors = {};

    if (!values.ccname) {
      errors.ccname = t("error.name");
    }

    if (
      !values.email ||
      !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(values.email)
    ) {
      errors.email = t("error.email");
    }

    if (!values.customerName) {
      errors.customerName = t("error.name");
    }

    if (!acceptedTerms) errors.acceptedTerms = t("form.terms3");

    try {
      const result = await getToken(window.mp, values.ccname);
      setMpToken(result);
    } catch (error) {
      if (error.length > 0) {
        if (error.findIndex((e) => e.field === "cardNumber") !== -1) {
          setErrorCardNumber(t("error.invalidCard"));
        }

        if (
          error.findIndex((e) => e.field === "expirationDate") !== -1 ||
          error.findIndex((e) => e.field === "expirationMonth") !== -1 ||
          error.findIndex((e) => e.field === "expirationYear") !== -1
        ) {
          setErrorCardDate(t("error.invalidDate"));
        }

        if (error.findIndex((e) => e.field === "securityCode") !== -1) {
          setErrorCardCvc(t("error.invalidCvc"));
        }
      } else {
        errors.token = true;
        setError("DATA_ERROR");
      }
    }

    if (Object.keys(errors).length > 0) {
      setBtnStatus("NORMAL");
    }

    return errors;
  };

  const sendForm = async () => {
    setError("");

    const card = {
      token: mpToken,
      cardholderName: values.ccname,
      cardholderEmail: values.email,
      bin: values.binCard,
      brand: values.cardBrand,
    };

    const customer = {
      name: values.customerName,
      email: values.email,
      phone: values.phone,
    };

    try {
      const pay = await payReservation(
        token,
        card,
        customer,
        reservation.remainingDepositAmount,
        1,
        values.processingMode,
        window.MP_DEVICE_SESSION_ID
      );
      if (pay.success) {
        setBtnStatus("FINISHED");
        setTimeout(() => {
          navigate({
            pathname: "/success",
            search: createSearchParams({ token: token }).toString(),
          });
        }, 1000);
      } else if (pay.status === "PENDING_CHALLENGE") {
        setThreeDsChallenge({
          paymentId: pay.chargeId,
          externalResourceURL: pay.threeDsExternalUrl,
          creq: pay.threeDsCreq,
        });
      } else {
        setBtnStatus("NORMAL");
        setError(pay.error);
      }
    } catch (e) {
      setBtnStatus("NORMAL");
      if (e.response && e.response.data && e.response.data.errorCode)
        setError(e.response.data.errorCode);
      else if (e.code === "ERR_NETWORK" || e.code === "ECONNABORTED") {
        setError("ERR_INTERNET_DISCONNECTED");
      } else {
        setError("API_ERROR_UNKNOWN");
      }
    }
  };

  const onThreeDsChallengeCompleted = async () => {
    setError("");
    try {
      const pay = await checkPayment(token, threeDsChallenge.paymentId);
      if (pay.success) {
        setBtnStatus("FINISHED");
        setTimeout(() => {
          setThreeDsChallenge(null);
          navigate({
            pathname: "/success",
            search: createSearchParams({ token: token }).toString(),
          });
        }, 10);
      } else if (pay.status === "PENDING_CHALLENGE") {
        setThreeDsChallenge({
          paymentId: pay.chargeId,
          externalResourceURL: pay.threeDsExternalUrl,
          creq: pay.threeDsCreq,
        });
      } else {
        setTimeout(() => {
          setThreeDsChallenge(null);
        }, 10);
        setBtnStatus("NORMAL");
        setError(pay.error);
      }
    } catch (e) {
      setTimeout(() => {
        setThreeDsChallenge(null);
      }, 10);
      setBtnStatus("NORMAL");
      setError("GENERIC_ERROR");
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    sendForm,
    validate
  );

  const handleCheck = (event) => {
    if (event.target.checked) {
      setAcceptedTerms(true);
      errors.acceptedTerms = null;
    } else {
      setAcceptedTerms(false);
    }
  };

  useEffect(() => {
    if (payment !== null) {
      let localeMp = "en-US";
      if (reservation?.language === "es") {
        localeMp = "es-AR";
      } else if (reservation?.language === "pt") {
        localeMp = "pt-BR";
      }

      window.mp = new window.MercadoPago(payment.key, {
        locale: localeMp,
      });

      setCardNumberElement(
        window.mp.fields.create("cardNumber", {
          placeholder: t("form.cardNumber"),
          style: {
            //fontFamily: '"Inter", sans-serif',
            color: "#fff",
            fontWeight: "700",
            fontSize: "1.2rem",
            placeholderColor: "#7e7e7e",
          },
        })
      );

      setExpirationDateElement(
        window.mp.fields.create("expirationDate", {
          placeholder: t("form.expiryDateFormat"),
          style: {
            color: "#fff",
            fontWeight: "700",
            fontSize: "1.2rem",
            placeholderColor: "#7e7e7e",
          },
        })
      );

      setSecurityCodeElement(
        window.mp.fields.create("securityCode", {
          placeholder: t("form.securityCode"),
          style: {
            color: "#fff",
            fontWeight: "700",
            fontSize: "1.2rem",
            placeholderColor: "#7e7e7e",
          },
        })
      );
    }

    setDefaultValues();
  }, []);

  useEffect(() => {
    if (cardNumberElement) {
      cardNumberElement.mount("form-checkout__cardNumber");

      cardNumberElement.on("binChange", async (data) => {
        const { bin } = data;
        setErrorCardNumber(null);

        try {
          if (!bin) {
            handleChange(null, "binCard", null);
            handleChange(null, "cardBrand", null);
          }

          if (bin && bin !== values.binCard) {
            const { results } = await window.mp.getPaymentMethods({
              bin,
            });

            if (results.length > 0) {
              const paymentMethod = results[0];
              updatePCIFieldsSettings(paymentMethod);
              handleChange(null, "cardBrand", paymentMethod.id);
              handleChange(
                null,
                "processingMode",
                paymentMethod.processing_mode
              );
            } else {
              setErrorCardNumber(t("error.cardNotSupported"));
            }

            handleChange(null, "binCard", bin);
          }
        } catch (e) {
          console.error("error getting payment methods: ", e);
        }
      });
    }

    //unmount mercadopago elements when component unmounts
    const onComponentUnmount = () => {
      if (cardNumberElement && cardNumberElement.unmount) {
        cardNumberElement.unmount();
      }
    };
    return onComponentUnmount;
  }, [cardNumberElement]);

  useEffect(() => {
    if (expirationDateElement) {
      expirationDateElement.mount("form-checkout__expirationDate");

      expirationDateElement.on("change", async (data) => {
        setErrorCardDate(null);
      });
    }

    //unmount mercadopago elements when component unmounts
    const onComponentUnmount = () => {
      if (expirationDateElement && expirationDateElement.unmount) {
        expirationDateElement.unmount();
      }
    };
    return onComponentUnmount;
  }, [expirationDateElement]);

  useEffect(() => {
    if (securityCodeElement) {
      securityCodeElement.mount("form-checkout__securityCode");

      securityCodeElement.on("change", async (data) => {
        setErrorCardCvc(null);
      });
    }

    //unmount mercadopago elements when component unmounts
    const onComponentUnmount = () => {
      if (securityCodeElement && securityCodeElement.unmount) {
        securityCodeElement.unmount();
      }
    };
    return onComponentUnmount;
  }, [securityCodeElement]);

  const updatePCIFieldsSettings = (paymentMethod) => {
    const { settings } = paymentMethod;

    const cardNumberSettings = settings[0].card_number;
    cardNumberElement.update({
      settings: cardNumberSettings,
    });

    const securityCodeSettings = settings[0].security_code;
    securityCodeElement.update({
      settings: securityCodeSettings,
    });
  };

  const setDefaultValues = () => {
    values.customerName = reservation.customerName;
    values.email = reservation.customerEmail;
    values.phone = reservation.customerPhone;
  };

  const treatments = [
    {
      value: "sr",
      label: "Sr.",
    },
    {
      value: "sra",
      label: "Sra.",
    },
    {
      value: "srta",
      label: "Srita.",
    },
  ];

  const getCardLogo = (cardBrand) => {
    if (!cardBrand || cardBrand.length <= 0) return;

    cardBrand = cardBrand.toLowerCase();

    if (cardBrand.includes("visa")) {
      return (
        <img
          src="https://cdn1.wellet.fun/images/credit-card/visa-logo.png"
          alt="Visa"
          style={{ height: "35px" }}
        />
      );
    } else if (cardBrand.includes("master")) {
      return (
        <img
          src="https://cdn1.wellet.fun/images/credit-card/mastercard-logo.png"
          alt="Mastercard"
          style={{ height: "35px" }}
        />
      );
    } else if (cardBrand === "amex") {
      return (
        <img
          src="https://cdn1.wellet.fun/images/credit-card/amex-logo.png"
          alt="American Express"
          style={{ height: "35px" }}
        />
      );
    }
  };

  const handleTerms = (e) => {
    e.preventDefault();

    if (!terms)
      getTermsAndConditions(
        showId,
        reservation.language,
        reservation.reservationType
      );

    setShowModal(true);
  };

  const getTermsAndConditions = async (showId, lang, reservationType) => {
    try {
      const result = await getTerms(showId, lang, reservationType);
      setTerms(result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {threeDsChallenge && (
        <MercadoPagoThreeDsModal
          {...threeDsChallenge}
          onChallengeCompleted={onThreeDsChallengeCompleted}
        />
      )}
      <form id="form-checkout" onSubmit={handleSubmit}>
        <div className="mt-4">
          <div className={`mt-4 ${styles.cardContainer}`}>
            <div className={styles.card}>
              <img
                src="https://cdn1.wellet.fun/images/credit-card/visa-logo.png"
                alt="visa"
              />
            </div>
            <div className={styles.card}>
              <img
                src="https://cdn1.wellet.fun/images/credit-card/mastercard-logo.png"
                alt="mastercard"
              />
            </div>
            <div className={styles.card}>
              <img
                src="https://cdn1.wellet.fun/images/credit-card/amex-logo.png"
                alt="americanexpress"
              />
            </div>
          </div>
          <div
            className={`field-container align-items-center position-relative mt-4 ${
              errorCardNumber && errorCardNumber.length > 0 ? "error" : ""
            }`}
          >
            <div className="field mp-field">
              <span className="floatingPlaceholder">
                {t("form.cardNumber")}
              </span>
              <div id="form-checkout__cardNumber" className="mp-input" />
            </div>
            {errorCardNumber && errorCardNumber.length > 0 ? (
              <div className="errorLabel mt-3">{errorCardNumber}</div>
            ) : null}
            <div style={{ position: "absolute", right: 30 }}>
              {getCardLogo(values.cardBrand)}
            </div>
          </div>

          <div className="mt-4">
            <InputForm
              name={"ccname"}
              type={"text"}
              value={values.ccname}
              error={errors.ccname}
              label={t("form.cardHolderName")}
              placeholder={t("form.cardHolder")}
              onChange={handleChange}
              dataCheckout={"fullName"}
            />
          </div>

          <div className="mt-4">
            <InputForm
              name={"email"}
              type={"email"}
              value={values.email}
              error={errors.email}
              icon="mail"
              label={"E-mail"}
              placeholder={t("form.reservationEmail")}
              onChange={handleChange}
            />
          </div>

          <div className="row mt-4">
            <div className="col-6 pr-1">
              <div
                className={`field-container ${
                  errorCardExpDate && errorCardExpDate.length > 0 ? "error" : ""
                }`}
              >
                <div className="field mp-field">
                  <span className="floatingPlaceholder">
                    {t("form.expiryDate")}
                  </span>
                  <div
                    id="form-checkout__expirationDate"
                    className="mp-input"
                  />
                </div>
                {errorCardExpDate && errorCardExpDate.length > 0 ? (
                  <div className="errorLabel mt-3">{errorCardExpDate}</div>
                ) : null}
              </div>
            </div>
            <div className="col-6 pl-1">
              <div
                className={`field-container ${
                  errorCardCvc && errorCardCvc.length > 0 ? "error" : ""
                }`}
              >
                <div className="field mp-field">
                  <span className="floatingPlaceholder">
                    {t("form.securityCode")}
                  </span>
                  <div id="form-checkout__securityCode" className="mp-input" />
                </div>
                {errorCardCvc && errorCardCvc.length > 0 ? (
                  <div className="errorLabel mt-3">{errorCardCvc}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="font-small text-center position-relative mt-4"
            style={{ paddingBottom: errors.acceptedTerms ? "27px" : "0" }}
          >
            <Checkbox
              label={
                <div>
                  {t("form.terms1")}{" "}
                  <u onClick={handleTerms}>{t("form.terms2")}</u>
                </div>
              }
              onChange={handleCheck}
            />
            {errors.acceptedTerms ? (
              <div className="errorLabel mt-1">{t("form.terms3")}</div>
            ) : null}
          </div>
        </div>
        {error ? (
          <div className="mt-4">
            <ErrorBox
              title={t("paymentErrors.title")}
              description={t("paymentErrors." + error)}
            />
          </div>
        ) : null}
        <div className="mt-4">
          <div>
            <ButtonProcessing
              status={btnStatus}
              text={t("form.payButton")}
              amount={<Money value={reservation.remainingDepositAmount} />}
              inProgressText={t("form.processing")}
              size="large"
              type="submit"
              onClick={handleSubmit}
              arrow
              color="#000"
              backgroundColor="var(--color-primary)"
              iconColor="#000"
            />
          </div>
        </div>
      </form>
      <Modal
        open={showModal}
        fullScreen
        showCloseButton={false}
        animationClass="slide-left-right"
        className={"terms-and-conditions"}
      >
        <Header onClick={handleModalClose} title={t("form.terms2")} />
        <div
          dangerouslySetInnerHTML={{ __html: terms }}
          className="container"
        ></div>
      </Modal>
    </>
  );
};

export default MercadoPagoForm;
