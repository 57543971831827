import React, { useState } from "react";
import "./input-form.css";
import "../../css/icon-wellet.css";

const InputForm = ({
  value,
  onChange,
  name,
  type,
  inputmode,
  error,
  icon,
  customClass = "",
  isDisabled = false,
  maxLength,
  classInput = "",
  label,
  placeholder,
  forceLowecase = false,
  dataCheckout,
}) => {
  const [forceToShow, setForceToShow] = useState(false);

  return (
    <div
      className={`field-container ${isDisabled ? "disabled" : ""} ${
        error ? "error" : ""
      }`}
    >
      <div className={`field ${customClass}`}>
        {icon && <i className={`icon-wellet icon-wellet-${icon}`} />}
        <div className={`${icon ? "input-icon" : ""}`}>
          <input
            name={name}
            type={forceToShow ? "text" : type}
            inputMode={inputmode}
            value={forceLowecase ? value?.toLowerCase() : value}
            onChange={onChange}
            className={classInput}
            disabled={isDisabled}
            maxLength={maxLength}
            placeholder={placeholder}
            data-checkout={dataCheckout}
          />
          {label && (
            <span
              className={`floatingPlaceholder ${classInput}`}
              style={{ fontWeight: "bold" }}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </div>
      </div>
      {error && error.length > 0 && (
        <div className={`errorLabel mt-3`}>{error}</div>
      )}
    </div>
  );
};

export default InputForm;
