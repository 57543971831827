export const resetReservation = () => {
  return {
    type: "RESET_RESERVATION"
  };
};

export const setReservation = (productInfo) => {
  return {
    type: "RESERVATION_INFO",
    payload: {
      showId: productInfo.showId,
      showName: productInfo.showName,
      products: productInfo.products,
      performance: productInfo.performance,
      date: productInfo.date,
      paxs: productInfo.paxs,
      callCenter: productInfo.callCenter,
    },
  };
};

export const setExtraPax = (productInfo) => {
  return {
    type: "RESERVATION_EXTRA_PAX",
    payload: productInfo,
  };
};

export const setReservationType = (type) => {
  return {
    type: "SET_RESERVATION_TYPE",
    payload: type,
  };
};
