import React from "react";
import { t } from "i18next";
import InfoIcon from "../Icons/InfoIcon";
import styles from "./product.module.css";
import Money from "../Money/Money";
import ProductCheck from "./ProductCheck";
import { useSelector } from "react-redux";

const Product = ({
  product,
  handleIncrement,
  handleDecrement,
  onInfoClick,
  isExtraPax,
  onlyOne = false,
  hasMoreInfo = false,
}) => {
  const maxAvailable = isExtraPax ? product.extraPax : product.stock;
  const isSoldOut = product.hasSoldOut || (maxAvailable && maxAvailable <= 0);
  const symbol = useSelector((state) => state.app.currencies[0].symbol);
  const onCheckClick = (e) => {
    if (e) handleIncrement();
    else handleDecrement();
  };

  const renderButtons = () => {
    return (
      <div className="col-auto ps-0">
        <div className={styles.quantityContainer}>
          {product.quantity > 0 && (
            <>
              <button
                className={`${styles.subtract}`}
                onClick={handleDecrement}
              >
                -
              </button>
            </>
          )}
          <button className={`${styles.add}`} onClick={handleIncrement}>
            +
          </button>
        </div>
      </div>
    );
  };

  const renderCheck = (isChecked) => {
    return (
      <div className="col-auto ps-0">
        <div className={styles.quantityCheckbox}>
          <ProductCheck
            onCheck={(e) => onCheckClick(e)}
            isChecked={isChecked}
          />
        </div>
      </div>
    );
  };

  const renderSubtitle = () => {
    if (isExtraPax) return product.name;

    switch (product.specialType) {
      case "UPGRADE":
        return t("pricePerPerson");
      case "EXTRASERVICE":
        return t("pricePerUnit");
      default:
        if (product.reservationType === "AREA")
          return (
            <>
              {t("upToGuest", { count: product.pax })}
              {product.extraPax && product.extraPax > 0 ? (
                <span>
                  {" "}
                  + {product.extraPax} {t("extraGuests")} ($)
                </span>
              ) : null}
            </>
          );
        else return t("pricePerPerson");
    }
  };

  return (
    <div
      className={`${isSoldOut ? styles.soldOut : ""} ${
        product.disabled ? styles.disabled : ""
      } mb-2`}
    >
      <div className="row">
        <div className={`col ${isSoldOut ? "" : "pe-0"}`}>
          <div className={styles.container}>
            <div className="row m-0" style={{ minHeight: "86px" }}>
              {product.images && product.images.length > 0 ? (
                <div className="col-auto my-auto  ps-2 pe-0">
                  <div className="position-relative">
                    <img
                      src={product.images[0]}
                      title={product.name}
                      className={styles.productImage}
                    />
                    {product.locationCode && (
                      <div
                        className={styles.productCode}
                        style={{ backgroundColor: product.hexColor }}
                      >
                        {product.locationCode}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div className={`col text-truncate my-auto px-2`}>
                <div className={`fw-bold text-truncate ${styles.productName}`}>
                  {isExtraPax ? t("extraGuest") : product.name}
                  {!isExtraPax ? (
                    <span
                      className={styles.productInfoIcon}
                      onClick={onInfoClick}
                    >
                      <InfoIcon size="13px" styles={{ marginTop: "-3px" }} />
                    </span>
                  ) : null}
                </div>

                <div
                  className={`${styles.productSubTitle} font-small text-truncate light`}
                  style={{ lineHeight: "1.1" }}
                >
                  {renderSubtitle()}
                </div>
                <div className={styles.productPrice}>
                  <span className="fw-bold">
                    <Money
                      value={
                        isExtraPax
                          ? product.prices.priceExtraPax
                          : product.prices.price
                      }
                      decimal={false}
                      symbol={symbol}
                    />
                  </span>
                </div>
              </div>

              {isSoldOut ? (
                <div className="col-auto my-auto">
                  <div className={styles.soldOutLabel}>{t("soldOut")}</div>
                </div>
              ) : null}

              {product.quantity > 0 && (
                <div
                  className={`col-auto ${styles.quantity} ps-0 pe-2 my-auto`}
                >
                  {product.quantity}
                </div>
              )}
            </div>
            {!isExtraPax && (
              <div className={styles.moreInfo} onClick={onInfoClick}>
                {t("moreInfo")}
              </div>
            )}
          </div>
        </div>
        {!isSoldOut
          ? onlyOne
            ? renderCheck(product.quantity > 0)
            : renderButtons()
          : null}
        {/* {!isSoldOut ? (
          <div className="col-auto ps-0">
            <div className={styles.quantityContainer}>
              {product.quantity > 0 && (
                <>
                  <button
                    className={`${styles.subtract}`}
                    onClick={handleDecrement}
                  >
                    -
                  </button>
                </>
              )}
              <button
                className={`${styles.add} ${
                  product.quantity >= maxAvailable ? "disabled" : ""
                }`}
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default Product;
