import React, { useContext, useEffect, useRef, useState } from "react";
import WelletContext from "../../context/WelletContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  skeletonEmptyPerformances,
  skeletonSchedule,
} from "../../components/Skeletons/SkeletonRestaurantReservation";
import {
  generateDisabledDates,
  getDateFromOffset,
  getLocale,
} from "../../helpers/dates";
import { useParams } from "react-router-dom";
import { convertTime12to24 } from "../../helpers/dates";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { setReservation } from "../../actions/reservationActions";
import ReservationSelector from "../../components/ReservationSelector/ReservationSelector";
import EmptyActivity from "../../components/Common/EmptyActivity/EmptyActivity";
import Calendar from "../../components/Common/Calendar/Calendar";
import Button from "../../components/Common/Button/Button";
import styles from "./reservation.module.css";
import ProductModal from "../../components/Common/ProductModal/ProductModal";
import Header from "../../components/Header/Header";
import ProductPerformanceSelector from "../../components/ProductPerformanceSelector/ProductPerformanceSelector";
import FixedButton from "../../components/Common/FixedButton/FixedButton";
import "./reservation.css";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { setReservationType } from "../../actions/reservationActions";
import ContentApp from "../../components/ContentApp/ContentApp";
const Reservation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showUrl } = useParams();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null);
  const [today, setToday] = useState(null);
  const [compareToday, setCompareToday] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [selectedPax, setSelectedPax] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [intervals, setIntervals] = useState([]);
  const [hasCallCenter, setHasCallCenter] = useState(null);
  const { reservationType } = useParams();
  const reservationTypeRestaurant = "RESTAURANT";
  const addMinutes = (date, minutes) => {
    let result = new Date(date);
    result.setMinutes(date.getMinutes() + minutes);
    return result;
  };

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/get/${showUrl}`,
        {
          params: {
            lang: language,
          },
        }
      );

      setShow(result.data);
      const todayDate = new Date(getDateFromOffset(result.data.utcOffset));
      const yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const firstDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        1
      );
      setToday(todayDate);
      setCompareToday(addMinutes(todayDate, 20));
      setMinDate(
        new Date(todayDate.getFullYear(), todayDate.getMonth(), 0 + 1)
      );
      setDisabledDates(generateDisabledDates(firstDate, yesterdayDate, locale));
      setSelectedDate(todayDate);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getSchedule = async (date, reservationType, showId) => {
    const formatedDate = format(date, "yyyy-MM-dd");

    setLoadingSchedule(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/GetSchedule?showId=${showId}&date=${formatedDate}&reservationType=${reservationType}`
      );

      if (result.data.length === 0) {
        setIntervals([]);
      }

      const formattedTimes = result.data?.map((time) => {
        const time24 = new Date(
          format(date, "yyyy-MM-dd") + "T" + convertTime12to24(time.timeStart)
        );

        if (time.timeMinutesStart >= 0 && time.timeMinutesStart <= 360)
          time24.setDate(time24.getDate() + 1);

        return { time24, soldOut: time.isSoldOut };
      });
      formattedTimes.sort();

      const allIntervals = formattedTimes.map(({ time24, soldOut }) => ({
        time: time24,
        soldOut,
      }));

      setIntervals(allIntervals);
      setLoadingSchedule(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (show && show.reservationTypes && Array.isArray(show.reservationTypes)) {
      const reservation = show.reservationTypes.find(
        (item) =>
          item.type.toLowerCase() === reservationTypeRestaurant.toLowerCase()
      );
      if (reservation) {
        const hasCallCenter = reservation.hasCallCenter;
        setHasCallCenter(hasCallCenter);
        // dispatch(setReservationType(reservationTypeRestaurant));
      }
    }
  }, [show]);
  useEffect(() => {
    dispatch(setReservationType(reservationTypeRestaurant));
    getShow();
  }, []);

  useEffect(() => {
    if (!show) return;
    if (today !== null) getSchedule(today, "RESTAURANT", show.id);
  }, [today, show]);

  const enableSelect = () => {
    setSelectDisabled(false);
  };

  const handleChangeDate = async (day) => {
    setSelectedPerformance(null);
    if (day !== undefined) {
      setSelectedDate(day);
      getSchedule(day, "RESTAURANT", show.id);
    }
    setCalendarVisible(!calendarVisible);
  };

  const onSelectPerformance = (performance) => {
    setSelectedPerformance(performance);
  };

  const handleContinue = () => {
    const reservationInfo = {
      showId: show.id,
      showName: show.name,
      products: null,
      performance: selectedPerformance,
      date: format(selectedDate, "yyyy-MM-dd"),
      paxs: selectedPax,
      callCenter: hasCallCenter,
    };

    dispatch(setReservation(reservationInfo));
    navigate({
      pathname: `/venue/${showUrl}/reservation-check`,
    });
  };

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="bottom-fixed">
      <Header
        title={
          <div className="text-capitalize px-3">
            {t("book") + " " + show.name}
          </div>
        }
      />

      {!loading && compareToday !== null && selectedDate !== null ? (
        <>
          <div className="mt-4 ">
            <ReservationSelector
              selectedPax={selectedPax}
              setSelectedPax={setSelectedPax}
              selectedDate={selectedDate}
              selectDisabled={selectDisabled}
              enableSelect={enableSelect}
              handleChangeDate={handleChangeDate}
              loading={loadingSchedule}
              setCalendarVisible={setCalendarVisible}
              calendarVisible={calendarVisible}
              setSelectDisabled={setSelectDisabled}
              calendarRef={calendarRef}
              initialSlide={0}
            />
          </div>
          <div className="mt-4">
            {!loadingSchedule ? (
              <>
                {intervals?.length > 0 && !loadingSchedule ? (
                  <div className="fade-up">
                    <div className="text-secondary fw-bold">
                      {t("selectTime")}
                    </div>
                    <div className="mt-3">
                      <ProductPerformanceSelector
                        performances={intervals}
                        onSelect={onSelectPerformance}
                        selectedPerformance={selectedPerformance}
                        language={language}
                        selectedDate={selectedDate}
                        loadingSchedule={loadingSchedule}
                        today={compareToday}
                      />
                    </div>
                  </div>
                ) : !loadingSchedule ? (
                  <EmptyActivity
                    icon={"notification"}
                    title={"No hay horarios disponibles"}
                  />
                ) : (
                  skeletonSchedule()
                )}
              </>
            ) : (
              <>{skeletonEmptyPerformances()}</>
            )}
          </div>
          <FixedButton>
            <Button
              text={t("continue")}
              size="large"
              className={`mt-2 mb-3 ${styles.btn}`}
              onClick={handleContinue}
              icon={true}
              disabled={!selectedPax || !selectedPerformance}
              typeIcon="arrow"
              iconColor="#000"
            />
          </FixedButton>
        </>
      ) : null}

      <ProductModal
        open={calendarVisible}
        fullScreen={true}
        animationClass="fade-up-horizontal"
        hasBackground
        showCloseButton={false}
      >
        <ContentApp>
          <Header onClick={() => setCalendarVisible(false)} showBack />
          <Calendar
            minDate={minDate}
            disabledDates={disabledDates}
            onSelectDate={handleChangeDate}
            selectedDate={selectedDate}
          />
        </ContentApp>
      </ProductModal>
    </div>
  );
};

export default Reservation;
