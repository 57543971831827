import React from "react";
import ExpiredIcon from "../../components/Icons/ExpiredIcon";
import ErrorIcon from "../../components/Icons/ErrorIcon";

const LinkCases = ({ error }) => {
  let text, icon;

  switch (error) {
    case "RESERVATION_EXPIRED":
      text = "Link de pago expirado";
      icon = <ExpiredIcon color="var(--color-primary)" size={"150px"} />;
      break;
    case "RESERVATION_NOT_WAITING_DEPOSIT":
      text = "La orden no esta disponible para ser pagada";
      icon = <ErrorIcon color="var(--color-primary)" size={"150px"} />;
      break;
    default:
      text = "Error desconocido";
      icon = <ErrorIcon color="var(--color-primary)" size={"150px"} />;
      break;
  }

  return (
    <div
      className="row align-items-center justify-content-center"
      style={{ marginTop: "40%" }}
    >
      {icon}
      <div className="text-center mt-5 font-medium">{text}</div>
    </div>
  );
};

export default LinkCases;
