import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPaidReservation } from "../../services/welletService";
import { useSearchParams } from "react-router-dom";
import SuccessCheckIcon from "../../components/Icons/SuccessCheckIcon";
import ErrorIcon from "../../components/Icons/ErrorIcon";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import WelletContext from "../../context/WelletContext";
import Button from "../../components/Common/Button/Button";
import { Spinner } from "../../components/Spinner/Spinner";
import "./payment-confirm.css";

const PaymentConfirm = () => {
  const { t, i18n } = useTranslation();
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const welletContext = useContext(WelletContext);
  const [sendingCode, setSendingCode] = useState(false);
  const getReservationPDF = async (token) => {
    setSendingCode(true);
    try {
      const response = await welletContext.apis.tickets.get(
        `/reservation/receipt/payment/file?token=${encodeURIComponent(token)}`
      );

      if (!response.data || !response.data.stream) {
        return;
      }
      const byteCharacters = atob(response.data.stream);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        response.data.fileName || "reservation-receipt.pdf"
      );
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF", error);
    }
    setSendingCode(false);
  };

  const getReservationInfo = async () => {
    try {
      const result = await getPaidReservation(token);
      if(result.show && result.show.name) {
        document.title = result.show.name;
      }
      setReservation(result);
      i18n.changeLanguage(result.language);
    } catch (error) {
      setError(error.response.data.errorCode);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReservationInfo();
  }, []);

  const renderSuccess = () => {
    return (
      <div className="payment-confirm-container">
        <div className="success-content p-3">
          <div className="text-center pb-5">
            <SuccessCheckIcon color={"var(--color-primary)"} size="135px" />
          </div>
          <div className="font-extra-large text-lighter-gray fw-bold ">
            {t("paymentConfirm.paymentSuccess")}
          </div>
          <div className="text-secondary">
            {t("paymentConfirm.paymentSuccessText")}
          </div>
          <div className=" text-secondary">
            {t("paymentConfirm.paymentSuccessTextTwo")}
          </div>
          <div className=" text-secondary pt-3 code-reserva">
            <h4>{t("paymentConfirm.ReservationCode")}</h4>
          </div>
          <h1> {reservation.referenceCode}</h1>

          <div className=" text-secondary pt-3">
            <h4>{t("paymentConfirm.AuthorizationCode")}</h4>
          </div>
          <h3>{reservation.paymentDetails[0].chargeId}</h3>
        </div>
        <div>
          <Button
            text={
              sendingCode ? (
                <div>
                  <Spinner size={25} color="#000" />
                </div>
              ) : (
                t("paymentConfirm.downloadReceipt")
              )
            }
            size="large"
            icon={sendingCode ? false : true}
            type="button"
            onClick={() => getReservationPDF(token)}
            color="#000"
            backgroundColor="var(--color-primary)"
            iconColor="#000"
            className="pointer"
          />
        </div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <>
        <div className="success-content">
          <div className="text-center">
            <ErrorIcon color={"var(--color-primary)"} size="135px" />
          </div>
          <div className="mt-5 px-2">{t("callApiError")}</div>
        </div>
      </>
    );
  };

  return loading ? (
    <LoadingScreen />
  ) : error === null ? (
    renderSuccess()
  ) : (
    renderError()
  );
};

export default PaymentConfirm;
