import React from "react";
import styles from "./customerSpecialRequest.module.css";
import SolidChevron from "../../Icons/SolidChevron";
import { useTranslation } from "react-i18next";

const CustomerSpecialRequest = ({ values, handleChange, open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={`mb-0 mr-2 fw-bold`} onClick={() => setOpen(!open)}>
        {t("specialRequest")}
        <SolidChevron
          size="20px"
          color="var(--color-primary)"
          styles={{
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            marginBottom: "-5px",
            marginLeft: ".5rem",
          }}
        />
      </div>

      {open && (
        <div className={`mt-3 ${styles.textareaContainer} fade-up`}>
          <textarea
            name="comments"
            placeholder={t("requestHere")}
            value={values.comments}
            onChange={handleChange}
            className={`p-3 ${styles.textarea}`}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerSpecialRequest;
