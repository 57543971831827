const initialState = {
  refCode: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_REF_CODE":
      return { ...state, refCode: action.payload };
    default:
      return state;
  }
};
