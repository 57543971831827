import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import './performance-selector.css';

const ProductPerformanceSelector = ({
  performances,
  selectedPerformance,
  onSelect,
  selectedIndex,
  today,
}) => {
  const [visiblePerformances, setVisiblePerformances] = useState(performances);
  const prevSelectedIndexRef = useRef();
  
  useEffect(() => {
    setVisiblePerformances(performances);
    prevSelectedIndexRef.current = selectedIndex;
  }, [performances, selectedPerformance]);

  return (
    <div className={`performance-selector row`}>
      {visiblePerformances
        .sort((a, b) => a.time - b.time)
        .map((performance, i) => {
          if (performance.time < today) return;

          const performanceText = format(performance.time, 'HH:mm');
          const isDisabled = performance.time < today || performance.soldOut;
          const isSelected = selectedPerformance === performanceText;

          return (
            <div className='col-3 pb-3' key={i}>
              <button
                className={`btn show-time-btn ${isDisabled ? 'disabled-performance' : ''} ${
                  isSelected ? 'font-weight-normal selected-performance' : ''
                }`}
                onClick={() => onSelect(performanceText)}
                disabled={isDisabled}
              >
                {performance ? performanceText : ''}
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default ProductPerformanceSelector;
